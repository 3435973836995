// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnailPreview{
    height: -moz-fit-content;
    height: fit-content;  
    cursor: pointer;
    margin: 0 1rem 1rem 0;  
    border:solid 0.1rem #C1C1C1;
    border-radius: 0.65rem;
    background-color: rgba(216, 220, 238, 1);
    width: 6rem;
    position: relative;
    color: rgba(0, 18, 126, 1);
}


.thumbnailPreview_itm {
    object-fit: cover;
    width: 100%;
    height: 6.5rem;
    border-radius: 0.65rem 0.65rem 0 0;
}

.thumbnailPreview_itm_icon {
    position: absolute;
    height: 12%;
    top: -5%;
    right: -5%;
    z-index: 1;
}

.thumbnailPreview_itm_name{
    word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/components/common/thumbnailPreview/styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,2BAA2B;IAC3B,sBAAsB;IACtB,wCAAwC;IACxC,WAAW;IACX,kBAAkB;IAClB,0BAA0B;AAC9B;;;AAGA;IACI,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,UAAU;IACV,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".thumbnailPreview{\n    height: fit-content;  \n    cursor: pointer;\n    margin: 0 1rem 1rem 0;  \n    border:solid 0.1rem #C1C1C1;\n    border-radius: 0.65rem;\n    background-color: rgba(216, 220, 238, 1);\n    width: 6rem;\n    position: relative;\n    color: rgba(0, 18, 126, 1);\n}\n\n\n.thumbnailPreview_itm {\n    object-fit: cover;\n    width: 100%;\n    height: 6.5rem;\n    border-radius: 0.65rem 0.65rem 0 0;\n}\n\n.thumbnailPreview_itm_icon {\n    position: absolute;\n    height: 12%;\n    top: -5%;\n    right: -5%;\n    z-index: 1;\n}\n\n.thumbnailPreview_itm_name{\n    word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
