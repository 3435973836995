import ReactDOM from "react-dom/client";
import App from "./App";

window.addEventListener(
  "dragover",
  function (e) {
    e.preventDefault();
  },
  false
);
window.addEventListener(
  "drop",
  function (e) {
    e.preventDefault();
  },
  false
);

const root = ReactDOM.createRoot(document.getElementById("root"));
const body = document.getElementsByTagName("body")[0];
body.style.margin = 0;
document.documentElement.style.fontSize = "13px";
root.render(<App />);
