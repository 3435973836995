import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormFinished } from "./formFinished/FormFinished";
import FormChat from "./formChat/FormChat";
import { FormIntro } from "./formIntro/FormIntro";
import { FormLang } from "./formLang/FormLang";
import { FormErr } from "./formErr/FormErr";
import { initForm } from "../../redux/actions/initForm";
import { setPInitials } from "../../redux/actions/setFormAttribute";
import { chatCred, DEF_LANG_OPT } from "../../util/const";
import {
  useQuery,
  getLang,
  getAgeFromDOB,
  getGenderFromCode,
} from "../../util/helper";
import {
  FORM_FONT_COLOR,
  CHAT_HEAD_STATUS_FONT_WEIGHT,
  FONT_FAMILY,
  FORM_BG_COLOR,
  CHAT_HEAD_STATUS_FONT_SIZE,
  CHAT_HEAD_STATUS_LINE_HEIGHT,
} from "../../res/resource";
import "./styles.css";

const Form = ({ isFormInit, isErrInForm, initFormData, setPInitialsInfo }) => {
  const query = useQuery();
  const [page, setPage] = useState(-1);
  const langOpt = getLang(query.get("lang"));


  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    const getCookieValue = (name) => (
      document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    )
    setIsMobile(getCookieValue("device_type") === "mobile")
  }, [])


  useEffect(() => {
    const pNameInitls = query.get("pNameInitl");
    const pAge = getAgeFromDOB(query.get("dob"));
    const pGender = getGenderFromCode(query.get("gendercode"));

    if ((isMobile === false && window.top !== window.self) || isMobile) {
      initFormData(
        query.get("ref_id"),
        query.get("token"),
        query.get("srcTyp"),
        pAge,
        pGender,
        langOpt ? langOpt.value : DEF_LANG_OPT
      );
      setPInitialsInfo({ pNameInitls, pAge, pGender });
    }
  }, [query, langOpt, initFormData, setPInitialsInfo, isMobile]);

  useEffect(() => {
    if (isFormInit) {
      setPage(2);
    }
  }, [isFormInit]);

  useEffect(() => {
    if ((isMobile === false && window.top === window.self) || isErrInForm) {
      setPage(4);
    }
  }, [isErrInForm, isMobile]);

  const renderPage = () => {
    switch (page) {
      case 0:
        return <FormLang {...{ setPage }} />;
      case 1:
        return <FormIntro {...{ setPage }} />;
      case 2:
        return <FormChat />;
      case 3:
        return <FormFinished />;
      case 4:
        return <FormErr />;
      default:
        break;
    }
  };

  return (
    <div
      className="form"
      style={{
        color: FORM_FONT_COLOR,
        fontFamily: FONT_FAMILY,
        fontSize: CHAT_HEAD_STATUS_FONT_SIZE,
        lineHeight: CHAT_HEAD_STATUS_LINE_HEIGHT,
        fontWeight: CHAT_HEAD_STATUS_FONT_WEIGHT,
        background: FORM_BG_COLOR,
      }}
    >
      {renderPage()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFormInit: state.formAttribute.session_id,
  isErrInForm: state.formStatus.error,
});

const mapDispatchToProps = (dispatch) => ({
  initFormData: (refID, auth, type = "", dob, gender, langId) =>
    dispatch(
      initForm({
        ...chatCred,
        ref_id: refID,
        token: auth,
        srcTyp: type,
        lang_id: langId,
        pAge: dob,
        pGender: gender,
      })
    ),
  setPInitialsInfo: (pInitials) => dispatch(setPInitials(pInitials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
