import correct_sample from "../../../../../assets/images/correct_sample.png";
import incorrect_sample from "../../../../../assets/images/incorrect_sample.png";
import filesInfoUploader from "../../../../../assets/images/filesInfoUploader.png";
import "./styles.css";

const FilesUploadInfo = ({ itm, removeFile, title, togglePreview }) => {
  return (
    <div className="filesUploadInfo">
      <div className="filesUploadInfo_row">
        <span className="filesUploadInfo_itm_name">
          {"Add Images of the selected locations"}
        </span>
        <img
          className="filesUploadInfo_image_a"
          src={filesInfoUploader}
          alt="filesUploadInfo_uploader_icn"
        />
      </div>
      <div className="filesUploadInfo_row filesUploadInfo_info_row filesUploadInfo_info_green">
        <div className="filesUploadInfo_image_border_b">
          <img
            className="filesUploadInfo_image_b"
            src={correct_sample}
            alt="filesUploadInfo_uploader_icn"
          />
        </div>
        <div className="filesUploadInfo_text_border_b">
          <h4>
            Do <br />
            Take images of the entire affected organ
          </h4>
        </div>
      </div>
      <div className="filesUploadInfo_row filesUploadInfo_info_row filesUploadInfo_info_red">
        <div className="filesUploadInfo_image_border_c">
          <img
            className="filesUploadInfo_image_c"
            src={incorrect_sample}
            alt="
          filesUploadInfo_uploader_icn"
          />
        </div>
        <div className="filesUploadInfo_text_border_c">
          <h4>
            Do not <br />
            Take images of affected organ closely
          </h4>
        </div>
      </div>
    </div>
  );
};

export { FilesUploadInfo };
