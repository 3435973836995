import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reqFirstQues } from "../../../../redux/actions/updtChat";
import {
  CHAT_HEAD_STATUS_FONT_SIZE,
  CHAT_HEAD_STATUS_LINE_HEIGHT,
} from "../../../../res/resource";
import ChatNavAction from "../../../fields/actions/chatNavAction/ChatNavAction";
import ChatQueue from "../../../fields/chatQueue/ChatQueue";
import UserInput from "../../../fields/userInput/UserInput";
import "./styles.css";

const ChatBody = ({ toggleResetDialogBox, isQuesLoading, isSearchLoading }) => {
  const [isCurQuesUpdt, updtCurQues] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqFirstQues());
  }, [dispatch]);

  return (
    <div
      className="chatBody"
      style={{
        fontSize: CHAT_HEAD_STATUS_FONT_SIZE,
        lineHeight: CHAT_HEAD_STATUS_LINE_HEIGHT,
      }}
    >
      <div id="chatArea" className="chatArea">
        <ChatQueue />
        {!isQuesLoading && <UserInput {...{ isCurQuesUpdt, updtCurQues }} />}
      </div>
      <ChatNavAction
        {...{
          toggleResetDialogBox,
          isMoveEnabled: !isQuesLoading && !isSearchLoading,
          isCurQuesUpdt,
          updtCurQues,
        }}
      />
    </div>
  );
};

export { ChatBody };
