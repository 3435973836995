import { combineReducers } from "redux";
import { formAttribute } from "./formAttribute";
import { formChat } from "./formChat";
import { formStatus } from "./formStatus";
import { formResponses } from "./formResponses";
import { snomedFindings } from "./snomedFindings";

const rootReducer = combineReducers({
  formAttribute,
  formChat,
  formStatus,
  formResponses,
  snomedFindings,
});

export { rootReducer };
