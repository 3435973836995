import {
  BACK_QUES_SUCCESS,
  CREATE_CHAT_SUCCESS,
  GET_CUR_QUES_SUCCESS,
  RESET_CHAT_SUCCESS,
  SUBMIT_RESP_SUCCESS,
} from "../types";

const initialState = {
  currQues: null,
  chatQ: [],
};

const formChat = (state = initialState, action) => {
  const { type, payload } = action;
  const { chatQ, currQues } = state;
  switch (type) {
    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        chatQ: payload.chatQ,
      };
    case RESET_CHAT_SUCCESS:
      return {
        currQues: null,
        chatQ: [],
      };
    case BACK_QUES_SUCCESS:
      let qidCount = 1;
      let qidLast = currQues?.qid;
      while (qidCount <= 2) {
        let pop_Itm = chatQ.pop();
        let lastItm = chatQ[chatQ.length - 1];
        if (!qidLast) {
          if (pop_Itm?.qid === lastItm?.qid) {
            chatQ.pop();
          }
          break;
        } else if (lastItm?.qid !== qidLast) {
          qidLast = lastItm?.qid;
          qidCount += 1;
        }
      }
      return {
        ...state,
        chatQ: [...chatQ],
        currQues: null,
      };
    case SUBMIT_RESP_SUCCESS:
      if (payload) {
        chatQ.push({
          src: "user",
          prompt: payload,
          qid: currQues.qid,
          type: currQues.type,
        });
      }
      return {
        ...state,
        chatQ: [...chatQ],
        currQues: null,
      };
    case GET_CUR_QUES_SUCCESS:
      if (currQues?.qid !== payload?.qid && payload?.prompt?.text) {
        chatQ.push({
          qid: payload.qid,
          src: "bot",
          prompt: payload.prompt,
          type: payload.type,
        });
      }
      return {
        ...state,
        chatQ: [...chatQ],
        currQues: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export { formChat };
