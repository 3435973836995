import def_file_prev from "../../../assets/images/def_file_prev.webp";
import del_icon from "../../../assets/images/cancel_icn.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getSessionIDFromState } from "../../../util/helper";
import axios from "axios";
import "./styles.css";

const ThumbnailPreview = ({ itm, removeFile, title, togglePreview }) => {
  const prevImgRef = useRef(null);
  const session_id = useSelector(getSessionIDFromState);
  const onImgErr = () => {
    prevImgRef.current.setAttribute("src", def_file_prev);
  };
  const [imgSrc, setImgSrc] = useState(null);
  useEffect(() => {
    if (itm instanceof File) {
      setImgSrc(URL.createObjectURL(itm));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/get_blob?token=${session_id}&blob_path=${itm}`
        )
        .then((response) => {
          setImgSrc("data:image/png;base64," + response.data);
        })
        .catch(() => {});
    }
  }, [itm, session_id]);

  return (
    <div className="thumbnailPreview">
      <img
        ref={prevImgRef}
        onError={onImgErr}
        className="thumbnailPreview_itm"
        src={imgSrc}
        alt="thumbnailPreview"
        onClick={() => togglePreview({ itm, removeFile, title })}
      />
      {removeFile && (
        <img
          className="thumbnailPreview_itm_icon"
          src={del_icon}
          alt="thumbnailPreview_itm_icon"
          onClick={() => removeFile(title)}
        />
      )}
      <span className="thumbnailPreview_itm_name">{title}</span>
    </div>
  );
};

export { ThumbnailPreview };
