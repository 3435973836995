import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import img_def from "../../../assets/images/img_def.png";
import tile_checked from "../../../assets/images/tile_checked.png";
import tile_unchecked from "../../../assets/images/tile_unchecked.png";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import "./styles.css";

const TileA = (props) => {
  const [checked, setChecked] = useState(props.checked);
  const langOpt = useSelector(getLangOptFromState);
  const imgRef = useRef(null);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onImgErr = () => {
    imgRef.current.setAttribute("src", img_def);
  };

  const handleClick = () => {
    props.onClick(props.itm, !checked);
    setChecked((prevChecked) => {
      return !prevChecked;
    });
  };

  return (
    <div className="tile_small" onClick={handleClick}>
      <div className="tile_small_box">
        <img
          ref={imgRef}
          className="tile_sm_img"
          src={`img/${props.imgName.toLowerCase()}.jpg`}
          onError={onImgErr}
          alt="tile_img"
        />
        <img
          className="tile_sm_status"
          src={checked ? tile_checked : tile_unchecked}
          alt="Tile Status"
        />
      </div>
      <div
        className={`tile_sm_name ${
          checked ? "tile_sm_checked" : "tile_sm_unchecked"
        }`}
      >
        <p className="tile_sm_name_p">
          {getTextFrmPrompt(props.itm.prompt.text, langOpt.value)}
        </p>
      </div>
    </div>
  );
};

export { TileA };
