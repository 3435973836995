// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formFinished {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0%;
    align-items: center;
    cursor: default;
}

.formFinished_img {
    width: 60vw;
    height: 32.5vh;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/form/formFinished/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".formFinished {\n    max-height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 0 0%;\n    align-items: center;\n    cursor: default;\n}\n\n.formFinished_img {\n    width: 60vw;\n    height: 32.5vh;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
