import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();

const middleWare = [thunk, sagaMiddleware];

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleWare)
);
sagaMiddleware.run(rootSaga);
export default store;
