import { QUES_TYPE } from "../../../../../util/const";
import { FilesUpload } from "../../filesUpload/FilesUpload";
import { MultiSelect } from "../MultiSelect";
import "./styles.css";

const MutiSelectChipsFileUpload = ({ getOptions }) => {
  return (
    <>
      {getOptions(
        undefined,
        undefined,
        undefined,
        undefined,
        "0px 2px 4px 3px rgba(255, 151, 60, 0.5)",
        ""
      )}
    </>
  );
};

export default MultiSelect(
  FilesUpload(MutiSelectChipsFileUpload),
  QUES_TYPE.MULTI_SELECT_CHIPS_FILE_UPLOAD
);
