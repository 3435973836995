import { useDispatch } from "react-redux";
import { reqPrevQues } from "../../../../redux/actions/updtChat";
import prev_ques from "../../../../assets/images/prev_ques.png";
import "./styles.css";

const PrevBtn = ({ isBackEnable, updtCurQues }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(reqPrevQues());
    updtCurQues(false);
  };

  return (
    <div className="prevDiv">
      {isBackEnable() && (
        <>
          <img
            onClick={handleClick}
            className="prevDiv_img"
            src={prev_ques}
            alt="Previous Ques"
          />
          {/* <span onClick={handleClick} className="prevDiv_txt">
            {BACK_PROMPT}
          </span> */}
        </>
      )}
    </div>
  );
};

export { PrevBtn };
