import { connect, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  AUTOCOMPLETE_SYMP_QUES_VAL,
  PRE_DOMINANT_SYMP_QUES_VAL,
  QUES_TYPE,
  maxVisibleOpts,
  ADD_TO_THE_LIST,
  ASSC_SYMP_TAG,
  NOT_APPLICABLE,
} from "../../../util/const";
import TextWords from "./textInput/textWords/TextWords";
import TextNumeric from "./textInput/textNumeric/TextNumeric";
import SingleSelectChips from "./singleSelect/singleSelectChips/SingleSelectChips";
import SingleSelectTiles from "./singleSelect/singleSelectTiles/SingleSelectTiles";
import MultiSelectChips from "./multiSelect/mutiSelectChips/MultiSelectChips";
import MultiSelectAutoText from "./multiSelect/multiSelectAutoText/MultiSelectAutoText";
import MultiSelectTiles from "./multiSelect/multiSelectTiles/MultiSelectTiles";
import MultiSelectSearch from "./multiSelect/multiSelectSearch/MultiSelectSearch";
import { saveResponse } from "../../../redux/actions/updtChat";
import MultiSelectAutoTextFileUpload from "./multiSelect/multiSelectAutoTextFileUpload/MultiSelectAutoTextFileUpload";
import MutiSelectChipsFileUpload from "./multiSelect/mutiSelectChipsFileUpload/MutiSelectChipsFileUpload";
import SingleSelectChipsFileUpload from "./singleSelect/singleSelectChipsFileUpload/SingleSelectChipsFileUpload";
import SingleSelectAutoTextFileUpload from "./singleSelect/singleSelectAutoTextFileUpload/SingleSelectAutoTextFileUpload";
import SingleSelectAutoText from "./singleSelect/singleSelectAutoText/SingleSelectAutoText";
import { updtCurResp } from "../../../redux/actions/updtCurResp";
import {
  filterByAgeGender,
  getPInitialsFrmState,
  isArrValid,
  isRespArrValid,
} from "../../../util/helper";
import "./styles.css";

const UserInput = ({
  currQues,
  formResp,
  saveResp,
  updtResp,
  isCurQuesUpdt,
  updtCurQues,
}) => {
  const pInitials = useSelector(getPInitialsFrmState);
  const onSave = useCallback(
    (respArr) => {
      saveResp(currQues.qid, respArr);
      updtCurQues(false);
    },
    [currQues, saveResp, updtCurQues]
  );

  const onUpdt = useCallback(
    (resp) => {
      updtResp(currQues.qid, resp);
      updtCurQues(isRespArrValid(resp));
    },
    [currQues, updtResp, updtCurQues]
  );

  const fltrReptSympResp = (responses) => {
    if (
      currQues?.qid === PRE_DOMINANT_SYMP_QUES_VAL ||
      currQues?.qid === AUTOCOMPLETE_SYMP_QUES_VAL ||
      currQues?.associated_flag === "True"
    ) {
      let asscSymptoms = [];
      for (var sympId in formResp) {
        if (
          formResp[sympId] &&
          sympId !== currQues.qid &&
          (sympId === PRE_DOMINANT_SYMP_QUES_VAL ||
            sympId === AUTOCOMPLETE_SYMP_QUES_VAL ||
            (sympId.includes("_") && sympId.split("_")[1] === ASSC_SYMP_TAG))
        ) {
          asscSymptoms = [
            ...asscSymptoms,
            ...formResp[sympId].filter(
              (itm) =>
                itm.value !== ADD_TO_THE_LIST && itm.value !== NOT_APPLICABLE
            ),
          ];
        }
      }
      return responses?.filter(
        (itm) =>
          filterByAgeGender(itm, pInitials["pAge"], pInitials["pGender"]) &&
          (!asscSymptoms.find((obj) => obj.value === itm.value) ? true : false)
      );
    } else {
      return responses;
    }
  };

  const getUserInputType = () => {
    if (currQues) {
      const savedVal = formResp[currQues?.qid];
      switch (currQues.type) {
        case QUES_TYPE.TEXT_WORDS:
          return (
            <TextWords
              {...{
                quesId: currQues.qid,
                isCurQuesUpdt,
                onSave,
                onUpdt,
                saved: isArrValid(savedVal) ? savedVal[0].text : "",
              }}
            />
          );
        case QUES_TYPE.TEXT_NUMERIC:
          return (
            <TextNumeric
              {...{
                quesId: currQues.qid,
                isCurQuesUpdt,
                onSave,
                onUpdt,
                saved: isArrValid(savedVal) ? savedVal[0].text : "",
              }}
            />
          );
        case QUES_TYPE.SINGLE_SELECT_CHIPS:
          if (currQues.responses.length <= maxVisibleOpts) {
            return (
              <SingleSelectChips
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: isArrValid(savedVal) ? savedVal[0] : {},
                }}
              />
            );
          } else {
            return (
              <SingleSelectAutoText
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: isArrValid(savedVal) ? savedVal[0] : {},
                }}
              />
            );
          }
        case QUES_TYPE.SINGLE_SELECT_CHIPS_FILE_UPLOAD:
          if (currQues.responses.length <= maxVisibleOpts) {
            return (
              <SingleSelectChipsFileUpload
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: isArrValid(savedVal) ? savedVal[0] : {},
                }}
              />
            );
          } else {
            return (
              <SingleSelectAutoTextFileUpload
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: isArrValid(savedVal) ? savedVal[0] : {},
                }}
              />
            );
          }
        case QUES_TYPE.SINGLE_SELECT_TILES:
          return (
            <SingleSelectTiles
              {...{
                quesId: currQues.qid,
                responses: fltrReptSympResp(currQues.responses),
                isCurQuesUpdt,
                onSave,
                onUpdt,
                saved: isArrValid(savedVal) ? savedVal[0] : {},
              }}
            />
          );
        case QUES_TYPE.MULTI_SELECT_CHIPS:
          if (currQues.responses.length <= maxVisibleOpts) {
            return (
              <MultiSelectChips
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: savedVal ? savedVal : [],
                }}
              />
            );
          } else {
            return (
              <MultiSelectAutoText
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: savedVal ? savedVal : [],
                }}
              />
            );
          }
        case QUES_TYPE.MULTI_SELECT_CHIPS_FILE_UPLOAD:
          if (currQues.responses.length <= maxVisibleOpts) {
            return (
              <MutiSelectChipsFileUpload
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: savedVal ? savedVal : [],
                }}
              />
            );
          } else {
            return (
              <MultiSelectAutoTextFileUpload
                {...{
                  quesId: currQues.qid,
                  responses: fltrReptSympResp(currQues.responses),
                  isCurQuesUpdt,
                  onSave,
                  onUpdt,
                  saved: savedVal ? savedVal : [],
                }}
              />
            );
          }
        case QUES_TYPE.MULTI_SELECT_TILES:
          return (
            <MultiSelectTiles
              {...{
                quesId: currQues.qid,
                responses: fltrReptSympResp(currQues.responses),
                isCurQuesUpdt,
                onSave,
                onUpdt,
                saved: savedVal ? savedVal : [],
              }}
            />
          );
        case QUES_TYPE.MULTI_SELECT_SEARCH:
          return (
            <MultiSelectSearch
              {...{
                quesId: currQues.qid,
                isCurQuesUpdt,
                onSave,
                onUpdt,
                saved: savedVal ? savedVal : [],
              }}
            />
          );
        default:
          onSave([]);
      }
    }
  };
  return <div className="userInput">{getUserInputType()}</div>;
};

const mapStateToProps = (state) => ({
  currQues: state.formChat.currQues,
  formResp: state.formResponses.formResponses,
});

const mapDispatchToProps = (dispatch) => ({
  saveResp: (id, value) => dispatch(saveResponse(id, value)),
  updtResp: (id, value) => dispatch(updtCurResp(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInput);
