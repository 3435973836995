import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import imageCompression from "browser-image-compression";
import {
  listOfLang,
  PRE_DOMINANT_SYMPS,
  AUTOCOMPLETE_SYMP_QUES_VAL,
  maxVisibleOpts,
  ADD_TO_THE_LIST,
} from "./const";

export const isArrValid = (arr) => arr && arr.length !== 0;
export const isRespArrValid = (arr) =>
  isArrValid(arr)
    ? arr.find((itm) => itm.value === ADD_TO_THE_LIST)
      ? arr.find((itm) => itm.value === ADD_TO_THE_LIST).raw_user_resp
        ? true
        : false
      : true
    : false;
export const isRespArrSkippable = (arr) =>
  isArrValid(arr)
    ? arr.find((itm) => itm.value === ADD_TO_THE_LIST)
      ? arr.find((itm) => itm.value === ADD_TO_THE_LIST).raw_user_resp
        ? true
        : false
      : true
    : true;
export const isOptValInArr = (arr, optVal) =>
  arr.find((itm) => itm.value === optVal) ? true : false;
export const getTextFrmPrompt = (itm, val) =>
  typeof itm === "string" || itm instanceof String ? itm : itm[val];

export const getLangOptFromState = (state) => state.formAttribute.langOpt;
export const getPInitialsFrmState = (state) => state.formAttribute.pInitials;
export const getFormFinishedStatus = (state) => state.formStatus.form_finished;
export const getQuesLoadingStatus = (state) => state.formStatus.ques_loading;
export const getSearchLoadingStatus = (state) =>
  state.formStatus.search_loading;
export const getSessionIDFromState = (state) => state.formAttribute.session_id;
export const getRespModel = (state) => state.formResponses.resp_model;

export const sendMessage = function (msg) {
  try {
    // eslint-disable-next-line
    Android.hideWebView(JSON.stringify(msg));
  } catch (error) {}
  window.parent.postMessage(msg, "*");
};

export const getImgName = (qid, itmName) => `${qid}_${itmName}`;
export const getLang = (val) =>
  listOfLang.filter((langItm) => langItm.value === val)[0];

export const getAgeFromDOB = (inpDOB) => {
  try {
    var today = new Date();
    const dateParts = inpDOB.split("-");
    var birthDate = new Date(`${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return isNaN(age) ? -1 : age;
  } catch (error) {
    return -1;
  }
};

export const getGenderFromCode = (inpGenderCode) => {
  switch (inpGenderCode) {
    case "1":
      return "male";
    case "2":
      return "female";
    case "3":
      return "others";
    case "4":
      return "not seleceted";
    default:
      return "";
  }
};

export const filterByAgeGender = (sympItm, age, gender) => {
  const ageGroup = sympItm?.age?.split("-");
  const sympGender = sympItm?.gender ? sympItm.gender.toLowerCase() : "all";
  let minAge = 0;
  let maxAge = 100;
  if (ageGroup?.length === 2) {
    minAge = parseInt(ageGroup[0]);
    maxAge = parseInt(ageGroup[1]);
  }

  if (
    (age === -1 || (age >= minAge && age <= maxAge)) &&
    (sympGender === "all" ||
      !gender ||
      gender === "others" ||
      gender === "not seleceted" ||
      gender === sympGender)
  ) {
    return true;
  } else {
    return false;
  }
};

export const formatUserResp = (val, langVal) => {
  if (val.length !== 0) {
    return {
      text: val.reduce((retString, itm) => {
        const raw_user_resp =
          itm.value === ADD_TO_THE_LIST && itm.raw_user_resp
            ? ` - (${itm.raw_user_resp})`
            : "";
        return (retString += `${
          (retString ? ", " : "") +
          getTextFrmPrompt(itm.text || itm.prompt.text, langVal)
        }${raw_user_resp}`);
      }, ""),
      image: [],
      type: "free-text",
    };
  }
};

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function hashCode(string) {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
  }
  return hash;
}

export function getDefaultVisibleOpts(qid, langVal, allResponses) {
  if (qid === AUTOCOMPLETE_SYMP_QUES_VAL) {
    const pre_dominant_symps_arr = allResponses.filter((itm) =>
      isOptValInArr(PRE_DOMINANT_SYMPS, itm.value)
    );
    return pre_dominant_symps_arr;
  } else {
    const def_opts_arr = allResponses.filter(
      (itm) => itm.value !== ADD_TO_THE_LIST
    );
    return def_opts_arr.slice(0, maxVisibleOpts);
  }
}

export function dataURItoFile(dataURI, fileName) {
  try {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const tmpBlob = new Blob([ab], { type: mimeString });
    return new File([tmpBlob], fileName, {
      type: "image/jpeg",
    });
  } catch {
    return null;
  }
}

export async function compressImage(inputFile) {
  try {
    const options = {
      maxSizeMB: 3,
    };
    const compressedFile = await imageCompression(inputFile, options);
    return new File([compressedFile], Date.now(), { type: "image/jpeg" });
  } catch (error) {
    return inputFile;
  }
}
