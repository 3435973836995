import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setOptScroll } from "../../../../redux/actions/setFormAttribute";
import {
  ADD_TO_THE_LIST,
  NOT_APPLICABLE,
  OTHERS_TXT_PLACEHOLDER,
  QUES_TYPE,
  SAVE_PROMPT,
} from "../../../../util/const";
import { getImgName } from "../../../../util/helper";
import { Chips } from "../../../common/chips/Chips";
import FreeText from "../textInput/freeText/FreeText";
import save_icn from "../../../../assets/images/save_icn.png";
import { ActionBtn } from "../../../common/actionButton/actionBtn/ActionBtn";
import tick_marked from "../../../../assets/images/tick_marked.png";
import upload_marked from "../../../../assets/images/upload_marked.png";
import "./styles.css";

const SingleSelect = (Component, singleSelectType) => {
  const SingleSelect = (props) => {
    const {
      quesId,
      responses,
      isCurQuesUpdt,
      onSave,
      onUpdt,
      saved,
      isSaveBtnEnable = false,
    } = props;
    const dispatch = useDispatch();
    const [val, setVal] = useState(saved);
    useEffect(() => {
      dispatch(setOptScroll());
    }, [dispatch]);
    const [isOthersEnabled, setOthersEnabled] = useState(
      saved.value === ADD_TO_THE_LIST
    );

    const [visibleTxt, setVisibleTxt] = useState("");

    const isAddEnable = () => {
      return responses.find((itm) => itm.value === ADD_TO_THE_LIST)
        ? true
        : false;
    };

    const isNANEnable = () => {
      return responses.find((itm) => itm.value === NOT_APPLICABLE)
        ? true
        : false;
    };

    const handleClick = (value) => {
      if (val.value !== value.value) {
        if (value.value === ADD_TO_THE_LIST) {
          setOthersEnabled(true);
        } else {
          setOthersEnabled(false);
        }
        setVal(value);
        onUpdt([value]);
        if (
          singleSelectType !== QUES_TYPE.SINGLE_SELECT_AUTOTEXT_FILE_UPLOAD &&
          singleSelectType !== QUES_TYPE.SINGLE_SELECT_CHIPS_FILE_UPLOAD
        ) {
          onSave([value]);
        }
      }
    };

    const getChkdMarkIfFilesUpload = (defChkdMark) => {
      if (
        (singleSelectType === QUES_TYPE.SINGLE_SELECT_AUTOTEXT_FILE_UPLOAD ||
          singleSelectType === QUES_TYPE.SINGLE_SELECT_CHIPS_FILE_UPLOAD) &&
        !val?.prompt?.files?.length
      ) {
        return upload_marked;
      } else {
        return defChkdMark;
      }
    };

    const getHighlightMarkIfFilesUpload = (defHiglightMark) => {
      if (!val.prompt?.files?.length) {
        return defHiglightMark;
      } else {
        return "0px 1px 5px 2px rgba(19, 136, 8, 0.5)";
      }
    };

    const getOptions = (
      OptionItem = Chips,
      options = responses,
      chips_mark = tick_marked,
      chips_unmark = "",
      highlight_mark = "",
      highlight_unmark = ""
    ) => {
      return (
        <>
          <div className="singleSelectOptWrapper">
            <div className="singleSelectOpt">
              {options
                .filter(
                  (itm) =>
                    itm.value !== ADD_TO_THE_LIST &&
                    itm.value !== NOT_APPLICABLE
                )
                .map((itm, idx) => (
                  <OptionItem
                    imgName={getImgName(quesId, itm.value)}
                    key={idx}
                    onClick={handleClick}
                    checked={val.value === itm.value}
                    itm={itm}
                    marked={
                      val.value === itm.value
                        ? getChkdMarkIfFilesUpload(chips_mark)
                        : chips_unmark
                    }
                    highlighted={
                      val.value === itm.value
                        ? getHighlightMarkIfFilesUpload(
                            highlight_mark
                          )
                        : highlight_unmark
                    }
                  />
                ))}
            </div>
          </div>
          <div className="singleSelectAddToListNAcontainer">
            {isAddEnable() && (
              <Chips
                key={ADD_TO_THE_LIST}
                onClick={handleClick}
                checked={val.value === ADD_TO_THE_LIST}
                marked={
                  val.value === ADD_TO_THE_LIST
                    ? getChkdMarkIfFilesUpload(chips_mark)
                    : chips_unmark
                }
                highlighted={
                  val.value === ADD_TO_THE_LIST
                    ? getHighlightMarkIfFilesUpload(highlight_mark)
                    : highlight_unmark
                }
                itm={responses.find((itm) => itm.value === ADD_TO_THE_LIST)}
              />
            )}
            {isNANEnable() && (
              <Chips
                key={NOT_APPLICABLE}
                onClick={handleClick}
                checked={val.value === NOT_APPLICABLE}
                marked={val.value === NOT_APPLICABLE ? chips_mark : ""}
                itm={responses.find((itm) => itm.value === NOT_APPLICABLE)}
              />
            )}
          </div>
          {isOthersEnabled && (
            <FreeText
              {...{
                isSkippable: true,
                onSave: () => {},
                onUpdt: handleOthersUpdate,
                saved: val.raw_user_resp,
                placeholder: OTHERS_TXT_PLACEHOLDER,
                isSaveBtnEnable: false,
                highlightIfEmpty: true,
              }}
            />
          )}
        </>
      );
    };

    const handleOthersUpdate = (valArr) => {
      const otherVal = valArr.length > 0 ? valArr[0]["text"] : "";
      const newVal = { ...val, raw_user_resp: otherVal };
      setVal(newVal);
      onUpdt([newVal]);
    };
    return (
      <>
        <Component
          {...{
            ...props,
            quesType: singleSelectType,
            getOptions,
            selectedOpt: val.value ? [val] : [],
            setSelectedOpt: (inpArr) => setVal(inpArr[0]),
            val,
            setVal,
            visibleTxt,
            setVisibleTxt,
          }}
        />
        {(isSaveBtnEnable ||
          isOthersEnabled ||
          singleSelectType === QUES_TYPE.SINGLE_SELECT_AUTOTEXT_FILE_UPLOAD ||
          singleSelectType === QUES_TYPE.SINGLE_SELECT_CHIPS_FILE_UPLOAD) && (
          <ActionBtn
            {...{
              isCurQuesUpdt,
              onClick: onSave,
              respArr: [val],
              btnPrompt: SAVE_PROMPT,
              actionBtn_icn: save_icn,
            }}
          />
        )}
      </>
    );
  };
  return SingleSelect;
};

export { SingleSelect };
