// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatQueue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
}

.chatQueue_itm_blocked{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/fields/chatQueue/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".chatQueue {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin-top: auto;\n}\n\n.chatQueue_itm_blocked{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
