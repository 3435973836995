import { connect } from "react-redux";
import { saveResponse } from "../../../../redux/actions/updtChat";
import next_ques from "../../../../assets/images/next_ques.png";
import "./styles.css";

const NextBtn = ({
  isNextEnable,
  currQues,
  formResp,
  saveResp,
  updtCurQues,
}) => {
  const handleClick = () => {
    saveResp(currQues.qid, formResp[currQues.qid]);
    updtCurQues(false);
  };
  // const getNextPrompt = () =>
  //   isArrValid(formResp[currQues.qid]) ? NEXT_PROMPT : SKIP_PROMPT;
  return (
    <div className="nextDiv">
      {isNextEnable() && (
        <>
          {/* <span onClick={handleClick} className="nextDiv_txt">
            {getNextPrompt()}
          </span> */}
          <img
            onClick={handleClick}
            className="nextDiv_img"
            src={next_ques}
            alt="Next Ques"
          />
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveResp: (id, value) => dispatch(saveResponse(id, value)),
});

export default connect(null, mapDispatchToProps)(NextBtn);
