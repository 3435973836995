// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actionBtn {
    display: flex;
    align-items: center;
    color: white;
    padding: 0.65rem 1.5rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4.28rem;
    cursor: pointer;
}

.actionBtn_enable {
    background-color: #00127E;
}

.actionBtn_disable {
    background-color: #7C7C7C;
}

.actionBtn_img {
    height: 1rem;
    object-fit: contain;
    margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/actionButton/actionBtn/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".actionBtn {\n    display: flex;\n    align-items: center;\n    color: white;\n    padding: 0.65rem 1.5rem;\n    width: fit-content;\n    border-radius: 4.28rem;\n    cursor: pointer;\n}\n\n.actionBtn_enable {\n    background-color: #00127E;\n}\n\n.actionBtn_disable {\n    background-color: #7C7C7C;\n}\n\n.actionBtn_img {\n    height: 1rem;\n    object-fit: contain;\n    margin-left: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
