// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prevDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prevDiv_img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    cursor: pointer;
    padding-right: 0.66rem;
}

.prevDiv_txt {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/actionButton/prevBtn/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".prevDiv {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.prevDiv_img {\n    width: 2.5rem;\n    height: 2.5rem;\n    object-fit: contain;\n    cursor: pointer;\n    padding-right: 0.66rem;\n}\n\n.prevDiv_txt {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
