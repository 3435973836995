import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, put, call, select, all } from "redux-saga/effects";
import { getSessionIDFromState } from "../../util/helper";
import {
  BACK_QUES_SUCCESS,
  FINISH_FORM_SUCCESS,
  GET_CUR_QUES_FAIL,
  GET_CUR_QUES_REQUEST,
  GET_CUR_QUES_SUCCESS,
  RESET_CHAT_SUCCESS,
  SKIP_FORM_FAIL_SAFE,
  SUBMIT_RESP_SUCCESS,
} from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const fetchCurQues = (session_id) => {
  return axios.request({
    method: "GET",
    headers: { "x-access-token": session_id },
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/fetch`,
  });
};

function* getCurQues() {
  try {
    const session_id = yield select(getSessionIDFromState);
    const res = yield call(fetchCurQues, session_id);
    if (res.data?.resp_status === "Form is complete") {
      yield put({ type: FINISH_FORM_SUCCESS, payload: res.data.resp_model });
    } else if (res.data) {
      yield put({ type: GET_CUR_QUES_SUCCESS, payload: res.data });
    } else {
      yield all([
        put({ type: GET_CUR_QUES_FAIL, payload: res.error }),
        put({
          type: SKIP_FORM_FAIL_SAFE,
          payload: { err: res.error, errInfo: "getCurQuesFailed" },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: GET_CUR_QUES_FAIL, payload: error }),
      put({
        type: SKIP_FORM_FAIL_SAFE,
        payload: { err: error, errInfo: "getCurQuesFailed" },
      }),
    ]);
  }
}

function* reqCurQues() {
  yield takeEvery(
    [
      GET_CUR_QUES_REQUEST,
      SUBMIT_RESP_SUCCESS,
      BACK_QUES_SUCCESS,
      RESET_CHAT_SUCCESS,
    ],
    getCurQues
  );
}

export { reqCurQues };
