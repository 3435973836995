// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInput_div{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
}

.textInput_wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    border-radius: 3rem;
    border: #C1C1C1 solid 0.1rem;
    box-shadow: 0px 0.3rem 0.3rem 0.3rem #D8DCEE99;
}

.textInput {
    width: 80%;
    border-width: 0;
    background-color: transparent;
}

.textInput_pen_icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    padding: 1.50rem 0.95rem 1.53rem 1.85rem ;
}

.errInp_box {
    border: #ff0038 solid 0.1rem;
}

.normalInp_box {
    border: #C1C1C1 solid 0.1rem;
}

.errInp:focus {
    outline: none;
    border: 0;
}

.normalInp:focus {
    outline: none;
    border: 0;
}

.errMssg {
    color: #ff0038;
    font-style: italic;
    margin: 0.1rem 1rem 0 auto;
}

@media (orientation: landscape){
    .textInput_div{
        width: 25rem;
        max-width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/fields/userInput/textInput/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,4BAA4B;IAC5B,8CAA8C;AAClD;;AAEA;IACI,UAAU;IACV,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI;QACI,YAAY;QACZ,cAAc;IAClB;AACJ","sourcesContent":[".textInput_div{\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 0.75rem;\n}\n\n.textInput_wrap {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    background: #FFFFFF;\n    border-radius: 3rem;\n    border: #C1C1C1 solid 0.1rem;\n    box-shadow: 0px 0.3rem 0.3rem 0.3rem #D8DCEE99;\n}\n\n.textInput {\n    width: 80%;\n    border-width: 0;\n    background-color: transparent;\n}\n\n.textInput_pen_icon {\n    width: 1rem;\n    height: 1rem;\n    object-fit: contain;\n    padding: 1.50rem 0.95rem 1.53rem 1.85rem ;\n}\n\n.errInp_box {\n    border: #ff0038 solid 0.1rem;\n}\n\n.normalInp_box {\n    border: #C1C1C1 solid 0.1rem;\n}\n\n.errInp:focus {\n    outline: none;\n    border: 0;\n}\n\n.normalInp:focus {\n    outline: none;\n    border: 0;\n}\n\n.errMssg {\n    color: #ff0038;\n    font-style: italic;\n    margin: 0.1rem 1rem 0 auto;\n}\n\n@media (orientation: landscape){\n    .textInput_div{\n        width: 25rem;\n        max-width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
