import { search } from "fast-fuzzy";
import { ADD_TO_THE_LIST, maxVisibleOpts } from "../const";
import { getTextFrmPrompt } from "../helper";

export const fuzzySearch = (str, strArr, langVal) => {
  const scoreStrArr = search(str, strArr, {
    keySelector: (itm) => [
      ...(itm.symp_synonyms ? itm.symp_synonyms : []),
      getTextFrmPrompt(itm.prompt.text, langVal),
    ],
    threshold:
      str.length < 3
        ? 0.95
        : str.length < 5
        ? 0.85
        : str.length < 7
        ? 0.75
        : 0.5,
  });
  return scoreStrArr
    .filter((itm) => itm.value !== ADD_TO_THE_LIST)
    .slice(0, maxVisibleOpts);
};
