import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThumbnailPreview } from "../../../common/thumbnailPreview/ThumbnailPreview";
import FilesUploadModel from "./filesUploadModel.js/FilesUploadModel";
import {
  formatUserResp,
  getLangOptFromState,
  isOptValInArr,
} from "../../../../util/helper";
import alertIcon from "../../../../assets/images/alert_icon.png";
import edit_files_icon from "../../../../assets/images/edit_files_icon.png";
import { ImagePreview } from "../../../common/ImagePreview/ImagePreview";
import { FilesUploadInfo } from "./filesUploadInfo/FilesUploadInfo";
import { AlertFormDialog } from "../../alertFormDialog/AlertFormDialog";
import { setOptScroll } from "../../../../redux/actions/setFormAttribute";
import { ADD_TO_THE_LIST } from "../../../../util/const";
import "./styles.css";

const FilesUpload = (Component) => {
  const FilesUpload = (props) => {
    const { quesId, quesType, onUpdt, selectedOpt, setSelectedOpt } = props;
    const dispatch = useDispatch();
    const [allFiles, setAllFiles] = useState(
      Array.isArray(selectedOpt)
        ? selectedOpt
        : selectedOpt?.value
        ? [selectedOpt]
        : []
    );
    const [curItm, setCurItm] = useState(null);
    const [fileUploadModel, setFileUploadModel] = useState(false);
    const [fileUploadSuccessAlert, setFileUploadSuccessAlert] = useState(false);
    const langOpt = useSelector(getLangOptFromState);
    const [previewState, setPreviewState] = useState(false);

    const togglePreview = (previewProps) => {
      setPreviewState((prevPreviewState) =>
        prevPreviewState ? false : previewProps
      );
    };

    const toggleSuccessAlert = useCallback(() => {
      setFileUploadSuccessAlert((prevSuccessAlert) => !prevSuccessAlert);
    }, []);

    const toggleFileUploadModel = useCallback(
      (files = [], hasChanged = true, othersTxt = "") => {
        if (fileUploadModel) {
          let newVal = [];
          selectedOpt.forEach((elm) => {
            if (elm.value === curItm.value) {
              elm.prompt.files = files;
              if (elm.value === ADD_TO_THE_LIST) {
                elm.raw_user_resp = othersTxt;
              }
              if (
                files?.length &&
                hasChanged &&
                (elm.value !== ADD_TO_THE_LIST || othersTxt)
              ) {
                setFileUploadSuccessAlert(true);
              }
            }
            newVal = [...newVal, elm];
          });
          setSelectedOpt(newVal);
          onUpdt(newVal);
          setAllFiles(newVal);
          dispatch(setOptScroll());
        }
        setFileUploadModel((prevFileUploadModel) => !prevFileUploadModel);
      },
      [fileUploadModel, selectedOpt, setSelectedOpt, onUpdt, curItm, dispatch]
    );

    useEffect(() => {
      if (quesType?.includes("single")) {
        setAllFiles((prevAllfiles) => {
          if (prevAllfiles[0]?.value !== selectedOpt[0]?.value) {
            setFileUploadModel(true);
            setCurItm(selectedOpt[0]);
          }
          return prevAllfiles;
        });
      } else {
        setAllFiles((prevAllfiles) => {
          if (prevAllfiles?.length > selectedOpt?.length) {
            return prevAllfiles.filter((itm) =>
              isOptValInArr(selectedOpt, itm.value)
            );
          } else {
            if (prevAllfiles?.length < selectedOpt?.length) {
              let m = selectedOpt.filter(
                (itm) => !isOptValInArr(prevAllfiles, itm.value)
              );
              m = m.filter((_itm) => _itm.value !== ADD_TO_THE_LIST);
              if (m.length) {
                setCurItm(m[0]);
                setFileUploadModel(true);
              }
            }
            return prevAllfiles;
          }
        });
      }
    }, [quesType, selectedOpt]);

    const getFilesPreview = (inpFilesArr = []) => (
      <div
        className={`filesUpload_preview ${
          inpFilesArr.length ? "preview_available" : "preview_empty"
        }`}
      >
        {inpFilesArr?.length === 0
          ? ""
          : inpFilesArr.map((itm, idx) => (
              <ThumbnailPreview
                {...{
                  removeFile,
                  itm: itm.file,
                  title: itm.title,
                  key: itm.file.name,
                  togglePreview,
                }}
              />
            ))}
      </div>
    );

    const getAllFilesPreview = () => {
      let allFilesPrev = [];
      allFiles?.forEach((elm) => {
        const tmpFilesArr = [];
        elm?.prompt?.files?.forEach((fileItm, idx) => {
          tmpFilesArr.push({
            file: fileItm,
            title: `${formatUserResp([elm], langOpt.value)?.text}_${idx + 1}`,
          });
        });
        if (tmpFilesArr?.length) {
          allFilesPrev.push({
            itm: elm,
            prev_itm: getFilesPreview(tmpFilesArr),
          });
        }
      });
      if (allFilesPrev?.length) {
        return (
          <div className={"filesUpload_preview filesUpload_preview_container"}>
            {allFilesPrev.map((elm, idx) => (
              <div className={"wrapper_available"} key={idx}>
                <h4 className="wrapper_h4">
                  {`Images for ${
                    formatUserResp([elm.itm], langOpt.value)?.text
                  }`}
                  <img
                    className="wrapper_actionBtn"
                    src={edit_files_icon}
                    alt="wrapper_actionBtn"
                    onClick={() => {
                      setCurItm(
                        selectedOpt.filter(
                          (_itm) => _itm.value === elm.itm.value
                        )[0]
                      );
                      setFileUploadModel(true);
                    }}
                  />
                </h4>
                {elm.prev_itm}
              </div>
            ))}
          </div>
        );
      } else {
        return <FilesUploadInfo />;
      }
    };

    const removeFile = (inpfileTitle) => {
      const newFiles = [];
      allFiles.forEach((fileOpt) => {
        const m = fileOpt.prompt.files?.filter(
          (fileItm, idx) =>
            `${formatUserResp([fileOpt], langOpt.value)?.text}_${idx + 1}` !==
            inpfileTitle
        );
        fileOpt.prompt.files = m;
        newFiles.push(fileOpt);
      });
      setSelectedOpt(newFiles);
      onUpdt(newFiles);
      setAllFiles(newFiles);
    };

    return (
      <div>
        <Component {...props} />
        {getAllFilesPreview()}
        {fileUploadModel && (
          <div className="filesUpload">
            <div className="filesUploadBackground" />
            <div className="filesUploadBox">
              <FilesUploadModel
                {...{
                  toggleFileUploadModel,
                  quesId,
                  curItm,
                  togglePreview,
                }}
              />
            </div>
          </div>
        )}
        {previewState && (
          <ImagePreview {...{ ...previewState, togglePreview, langOpt }} />
        )}
        {fileUploadSuccessAlert && (
          <AlertFormDialog
            {...{
              header: `Images of ${
                formatUserResp([curItm], langOpt.value)?.text
              }  uploaded`,
              toggleSuccessAlert,
              imgSrc: alertIcon,
              footer: `Select next body part where the issue is located`,
            }}
          />
        )}
      </div>
    );
  };
  return FilesUpload;
};
export { FilesUpload };
