import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchText } from "../../../../common/searchText/SearchText";
import { QUES_TYPE } from "../../../../../util/const";
import { fuzzySearch } from "../../../../../util/SearchAlgorithm/fuzzzy_search";
import { SingleSelect } from "../SingleSelect";
import {
  getDefaultVisibleOpts,
  getLangOptFromState,
} from "../../../../../util/helper";
import { FilesUpload } from "../../filesUpload/FilesUpload";
import "./styles.css";

const SingleSelectAutoTextFileUpload = ({
  quesId,
  responses,
  getOptions,
  val,
  visibleTxt,
  setVisibleTxt,
}) => {
  const langOpt = useSelector(getLangOptFromState);
  const [visibleOpt, setVisibleOpt] = useState([
    ...(val.value ? [val] : []),
    ...getDefaultVisibleOpts(
      quesId,
      langOpt.value,
      responses?.filter((itm) => itm.value !== val.value)
    ),
  ]);

  useEffect(() => {
    let visible_chips = document.getElementsByClassName("chips");
    let chips_container = document.getElementsByClassName(
      "singleSelectOptWrapper"
    )[0];
    chips_container.style.marginBottom = "0.5rem";
    let areaHeight = chips_container.offsetTop + chips_container.offsetHeight;

    for (let index = 0; index < visible_chips.length; index++) {
      const element = visible_chips[index];
      var top = element.offsetTop;
      var height = element.offsetHeight;
      var tg = top + height;

      if (tg > areaHeight && tg - areaHeight < height) {
        var sum = chips_container.offsetHeight + (tg - areaHeight);
        chips_container.style.maxHeight = sum + "px";
        break;
      }
    }
  }, [visibleOpt]);

  useEffect(() => {
    const strToSearch = visibleTxt.trim();
    let newVisibleOpt = [];
    const searchList = responses?.filter((itm) => itm.value !== val.value);
    if (strToSearch) {
      newVisibleOpt = fuzzySearch(strToSearch, searchList, langOpt.value);
    } else {
      newVisibleOpt = getDefaultVisibleOpts(quesId, langOpt.value, searchList);
    }
    setVisibleOpt(val.value ? [...[val], ...newVisibleOpt] : newVisibleOpt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTxt]);

  return (
    <>
      <SearchText {...{ visibleTxt, handleChange: setVisibleTxt }} />
      {getOptions(
        undefined,
        visibleOpt,
        undefined,
        undefined,
        "0px 2px 4px 3px rgba(255, 151, 60, 0.5)",
        ""
      )}
    </>
  );
};

export default SingleSelect(
  FilesUpload(SingleSelectAutoTextFileUpload),
  QUES_TYPE.SINGLE_SELECT_AUTOTEXT_FILE_UPLOAD
);
