import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import "./styles.css";

const Chips = (props) => {
  const [checked, setChecked] = useState(props.checked);
  const langOpt = useSelector(getLangOptFromState);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const getCheckedIco = () =>
    props.marked ? (
      <img
        className="chip_tick_mark"
        src={props.marked}
        alt="Chip Tick Marked"
      />
    ) : (
      ""
    );

  const handleClick = () => {
    props.onClick(JSON.parse(JSON.stringify(props.itm)), !checked);
  };

  return (
    <>
      <div
        className={`chips ${checked ? "chips_checked" : "chips_unchecked"} ${
          props.marked
            ? props.marked === "plus"
              ? "chips_spcl_marked"
              : "chips_marked"
            : "chips_unmarked"
        }`}
        onClick={handleClick}
        style={{ boxShadow: props.highlighted }}
      >
        <span className="chips_span">
          {getTextFrmPrompt(props.itm.prompt.text, langOpt.value)}
        </span>
        {getCheckedIco()}
      </div>
    </>
  );
};

export { Chips };

