// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchText {
    margin-bottom: 1.30rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    border-radius: 3.07rem;
    border: #C1C1C1 solid 0.077rem;
}

.searchText_input { 
    width: 90%;
    border-width: 0;
    background-color: transparent;
}

.searchText_input:focus {
    outline: none;
}

.search_icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    padding: 1.50rem 0.95rem 1.53rem 1.85rem ;
}

.searchText_input_img{
    height: 1.53rem;
    width: 1.53rem;
    padding: 1.27rem 1.69rem 1.27rem 0.77rem
}

@media (orientation: landscape){
    .searchText{
        width: 25rem;
        max-width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/searchText/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,eAAe;IACf,cAAc;IACd;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,cAAc;IAClB;AACJ","sourcesContent":[".searchText {\n    margin-bottom: 1.30rem;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    background: #FFFFFF;\n    border-radius: 3.07rem;\n    border: #C1C1C1 solid 0.077rem;\n}\n\n.searchText_input { \n    width: 90%;\n    border-width: 0;\n    background-color: transparent;\n}\n\n.searchText_input:focus {\n    outline: none;\n}\n\n.search_icon {\n    width: 1rem;\n    height: 1rem;\n    object-fit: contain;\n    padding: 1.50rem 0.95rem 1.53rem 1.85rem ;\n}\n\n.searchText_input_img{\n    height: 1.53rem;\n    width: 1.53rem;\n    padding: 1.27rem 1.69rem 1.27rem 0.77rem\n}\n\n@media (orientation: landscape){\n    .searchText{\n        width: 25rem;\n        max-width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
