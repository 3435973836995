import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, put, call, select, all } from "redux-saga/effects";
import {
  formatUserResp,
  getLangOptFromState,
  getSessionIDFromState,
} from "../../util/helper";
import {
  SKIP_FORM_FAIL_SAFE,
  SUBMIT_RESP_FAIL,
  SUBMIT_RESP_REQUEST,
  SUBMIT_RESP_SUCCESS,
} from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const submitResp = (payload, session_id) => {
  return axios.request({
    method: "PUT",
    headers: { "x-access-token": session_id },
    data: payload,
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/submit`,
  });
};

function* saveResp({ payload }) {
  try {
    const session_id = yield select(getSessionIDFromState);
    const res = yield call(submitResp, payload, session_id);
    if (res.data) {
      const langOpt = yield select(getLangOptFromState);
      var userResponse = {
        qid: payload.get("qid"),
        values: payload.getAll("values").map((itm) => JSON.parse(itm)),
      };

      const frmtDta = formatUserResp(userResponse.values, langOpt.value);
      yield put({ type: SUBMIT_RESP_SUCCESS, payload: frmtDta });
    } else {
      yield all([
        put({ type: SUBMIT_RESP_FAIL, payload: res.error }),
        put({
          type: SKIP_FORM_FAIL_SAFE,
          payload: { err: res.error, errInfo: "saveRespFailed" },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: SUBMIT_RESP_FAIL, payload: error }),
      put({
        type: SKIP_FORM_FAIL_SAFE,
        payload: { err: error, errInfo: "saveRespFailed" },
      }),
    ]);
  }
}

function* saveResponse() {
  yield takeEvery(SUBMIT_RESP_REQUEST, saveResp);
}

export { saveResponse };
