import { validation } from "./validation";
import { TextInput } from "../TextInput";
import "./styles.css";

const TextWords = ({ getInputTextDiv }) => {
  return <>{getInputTextDiv()}</>;
};

export default TextInput(TextWords, {
  validation,
});
