import { DEF_LANG_OPT } from "../../util/const";
import { getLang } from "../../util/helper";
import {
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  SET_INITIALS,
  SET_LANG_SUCCESS,
  SET_OPT_SCROLLABLE,
} from "../types";

const initialState = {
  langOpt: getLang(DEF_LANG_OPT),
  pInitials: {
    pNameInitls: "",
    pAge: -1,
    pGender: "",
  },
  isOptScrollable: false,
  session_id: "",
};

const formAttribute = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LANG_SUCCESS:
      return {
        ...state,
        langOpt: payload,
      };
    case SET_INITIALS:
      return {
        ...state,
        pInitials: payload,
      };
    case SET_OPT_SCROLLABLE:
      return {
        ...state,
        isOptScrollable: !state.isOptScrollable,
      };
    case CREATE_CHAT_REQUEST:
      return {
        ...state,
        session_id: "",
      };
    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        session_id: payload.Token,
        langOpt: payload.langOpt,
      };
    default:
      return {
        ...state,
      };
  }
};

export { formAttribute };
