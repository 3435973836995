// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatNavAction {
    padding: 0 6%;
    height: 5rem; 
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/fields/actions/chatNavAction/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".chatNavAction {\n    padding: 0 6%;\n    height: 5rem; \n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
