// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userInput {
    margin-top: 1.5rem;
}

@media (orientation:landscape){
    .userInput {
        max-width: 70%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/fields/userInput/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".userInput {\n    margin-top: 1.5rem;\n}\n\n@media (orientation:landscape){\n    .userInput {\n        max-width: 70%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
