import { QUES_TYPE } from "../../../../../util/const";
import { MultiSelect } from "../MultiSelect";
import "./styles.css";

const MultiSelectChips = ({ getOptions }) => {
  return <>{getOptions()}</>;
};


export default MultiSelect(MultiSelectChips, QUES_TYPE.MULTI_SELECT_CHIPS);
