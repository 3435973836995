import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchText } from "../../../../common/searchText/SearchText";
import { QUES_TYPE } from "../../../../../util/const";
import { fuzzySearch } from "../../../../../util/SearchAlgorithm/fuzzzy_search";
import { MultiSelect } from "../MultiSelect";
import {
  getDefaultVisibleOpts,
  getLangOptFromState,
  isOptValInArr,
} from "../../../../../util/helper";
import "./styles.css";

const MultiSelectAutoText = ({
  quesId,
  responses,
  getOptions,
  selectedOpt,
  visibleTxt,
  setVisibleTxt,
}) => {
  const langOpt = useSelector(getLangOptFromState);
  const [visibleOpt, setVisibleOpt] = useState([
    ...selectedOpt,
    ...getDefaultVisibleOpts(
      quesId,
      langOpt.value,
      responses.filter((itm) => !isOptValInArr(selectedOpt, itm.value))
    ),
  ]);

  useEffect(() => {
    let visible_chips = document.getElementsByClassName("chips");
    let chips_container = document.getElementsByClassName(
      "multiSelectOptWrapper"
    )[0];
    chips_container.style.marginBottom = "0.5rem";
    let areaHeight = chips_container.offsetTop + chips_container.offsetHeight;

    for (let index = 0; index < visible_chips.length; index++) {
      const element = visible_chips[index];
      var top = element.offsetTop;
      var height = element.offsetHeight;
      var tg = top + height;

      if (tg > areaHeight && tg - areaHeight < height) {
        var sum = chips_container.offsetHeight + (tg - areaHeight);
        chips_container.style.maxHeight = sum + "px";
        break;
      }
    }
  }, [visibleOpt]);

  useEffect(() => {
    const strToSearch = visibleTxt.trim();
    let newVisibleOpt = [];
    const searchList = responses?.filter(
      (itm) => !isOptValInArr(selectedOpt, itm.value)
    );
    if (strToSearch) {
      newVisibleOpt = fuzzySearch(strToSearch, searchList, langOpt.value);
    } else {
      newVisibleOpt = getDefaultVisibleOpts(quesId, langOpt.value, searchList);
    }
    setVisibleOpt([...selectedOpt, ...newVisibleOpt]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTxt]);

  return (
    <>
      <SearchText {...{ visibleTxt, handleChange: setVisibleTxt }} />
      {getOptions(undefined, visibleOpt)}
    </>
  );
};

export default MultiSelect(
  MultiSelectAutoText,
  QUES_TYPE.MULTI_SELECT_AUTOTEXT
);
