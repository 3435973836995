import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSnomedFindings } from "../../../../../redux/actions/getSnomedFindings";
import { SearchText } from "../../../../common/searchText/SearchText";
import { QUES_TYPE } from "../../../../../util/const";
import { MultiSelect } from "../MultiSelect";
import { isOptValInArr } from "../../../../../util/helper";
import "./styles.css";

const MultiSelectSearch = ({
  getOptions,
  selectedOpt,
  visibleTxt,
  setVisibleTxt,
}) => {
  const dispatch = useDispatch();
  const [visibleOpt, setVisibleOpt] = useState(selectedOpt);
  const snomedFindings = useSelector(
    (state) => state.snomedFindings.snomedFindings
  );

  useEffect(() => {
    setVisibleOpt([
      ...selectedOpt,
      ...snomedFindings.filter((itm) => !isOptValInArr(selectedOpt, itm.value)),
    ]);
  }, [selectedOpt, snomedFindings]);

  const handleEnter = (e) => {
    const code = e.keyCode || e.which;
    const inpStr = e.target.value;
    const strToSearch = inpStr.trim();
    if (strToSearch && code === 13) {
      dispatch(getSnomedFindings(strToSearch));
    }
    setVisibleTxt(inpStr);
  };

  return (
    <>
      <SearchText {...{ visibleTxt, handleEnter }} />
      {getOptions(undefined, visibleOpt)}
    </>
  );
};

export default MultiSelect(MultiSelectSearch, QUES_TYPE.MULTI_SELECT_SEARCH);
