// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formLang {
    margin: 0 3.5vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formLang_Body {
    margin: 16vh 0 0 0;
}

.formLang_next{
    text-align: center;
    padding: 0 15vw;
    margin: 0 auto 5.5vh;
    border-radius: 40px;
    cursor: pointer;
}

.formLang_mssg {
    width: 70vw;
    border-radius: 0 11.125vw 6.25vh 11.125vw;
    background-color: #FFFFFF;
    margin: 0 0 0 20vw;
}

.formLang_greet {
    padding: 0.1% 10vw;
}

.formLang_img {
    width: 21vw;
    height: 12vh;
    object-fit: contain;
}

.formLang_opt {
    margin: 1.5vh 0 0 25vw;
}`, "",{"version":3,"sources":["webpack://./src/components/form/formLang/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,yCAAyC;IACzC,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".formLang {\n    margin: 0 3.5vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.formLang_Body {\n    margin: 16vh 0 0 0;\n}\n\n.formLang_next{\n    text-align: center;\n    padding: 0 15vw;\n    margin: 0 auto 5.5vh;\n    border-radius: 40px;\n    cursor: pointer;\n}\n\n.formLang_mssg {\n    width: 70vw;\n    border-radius: 0 11.125vw 6.25vh 11.125vw;\n    background-color: #FFFFFF;\n    margin: 0 0 0 20vw;\n}\n\n.formLang_greet {\n    padding: 0.1% 10vw;\n}\n\n.formLang_img {\n    width: 21vw;\n    height: 12vh;\n    object-fit: contain;\n}\n\n.formLang_opt {\n    margin: 1.5vh 0 0 25vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
