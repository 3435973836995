import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ChatQueueBlock } from "./chatQueueBlock/ChatQueueBlock";
import { AUTOCOMPLETE_SYMP_QUES_VAL } from "../../../util/const";
import "./styles.css";

const ChatQueue = ({ chatQ, currQues, isOptScrollable }) => {
  const currQuesRef = useRef(null);
  const scrollToBottom = () => {
    const chatAreaDiv = document.getElementById("chatArea");
    chatAreaDiv.scrollTo({
      top: currQuesRef?.current?.offsetTop - chatAreaDiv.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(scrollToBottom, [currQues, isOptScrollable]);

  const getChatItm = () => {
    return chatQ.length === 0
      ? ""
      : chatQ.map((itm, idx) => (
          <div
            key={idx}
            className={`chatQueue_itm ${
              itm.src === "bot" && itm.qid === AUTOCOMPLETE_SYMP_QUES_VAL
                ? "chatQueue_itm_blocked"
                : "chatQueue_itm_enabled"
            }`}
            ref={idx === chatQ.length - 1 ? currQuesRef : null}
          >
            <ChatQueueBlock key={idx} {...itm} />
          </div>
        ));
  };

  return <div className="chatQueue">{getChatItm()}</div>;
};

const mapStateToProps = (state) => ({
  chatQ: state.formChat.chatQ,
  currQues: state.formChat.currQues,
  isOptScrollable: state.formAttribute.isOptScrollable,
});

export default connect(mapStateToProps)(ChatQueue);
