import { validation } from "./validation";
import { TextInput } from "../TextInput";
import "./styles.css";

const TextNumeric = ({ getInputTextDiv }) => {
  return <>{getInputTextDiv()}</>;
};

export default TextInput(TextNumeric, {
  validation,
});
