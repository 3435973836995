import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setOptScroll } from "../../../../redux/actions/setFormAttribute";
import {
  ADD_TO_THE_LIST,
  NOT_APPLICABLE,
  OTHERS_TXT_PLACEHOLDER,
  QUES_TYPE,
  SAVE_PROMPT,
} from "../../../../util/const";
import { getImgName, isOptValInArr } from "../../../../util/helper";
import { Chips } from "../../../common/chips/Chips";
import FreeText from "../textInput/freeText/FreeText";
import { ActionBtn } from "../../../common/actionButton/actionBtn/ActionBtn";
import save_icn from "../../../../assets/images/save_icn.png";
import tick_marked from "../../../../assets/images/tick_marked.png";
import upload_marked from "../../../../assets/images/upload_marked.png";
import { ClearResponseDialog } from "../../clearResponseDialog/ClearResponseDialog";
import "./styles.css";

const MultiSelect = (Component, multiSelectType) => {
  const MultiSelect = (props) => {
    const {
      quesId,
      responses,
      isCurQuesUpdt,
      onSave,
      onUpdt,
      saved,
      isSaveBtnEnable = true,
    } = props;
    const dispatch = useDispatch();
    const [selectedOpt, setSelectedOpt] = useState(saved);
    const [canProceed, setProceed] = useState(false);

    useEffect(() => {
      dispatch(setOptScroll());
    }, [dispatch]);
    const [isOthersEnabled, setOthersEnabled] = useState(
      saved.find((itm) => itm.value === ADD_TO_THE_LIST)
    );

    const getRawValFrmSelected = (selectedOptVal) => {
      const filteredOpt = selectedOpt.find(
        (itm) => itm.value === selectedOptVal
      );
      return filteredOpt ? filteredOpt.raw_user_resp : "";
    };

    const [visibleTxt, setVisibleTxt] = useState("");

    const isAddEnable = () => {
      return responses.find((itm) => itm.value === ADD_TO_THE_LIST)
        ? true
        : false;
    };

    const isNANEnable = () => {
      return responses.find((itm) => itm.value === NOT_APPLICABLE)
        ? true
        : false;
    };

    const handleChange = (value, select) => {
      let newVal = [];
      if (select) {
        if (value.value === NOT_APPLICABLE) {
          newVal = [value];
          setOthersEnabled(!select);
        } else if (
          value.value === ADD_TO_THE_LIST &&
          (multiSelectType === QUES_TYPE.MULTI_SELECT_SEARCH ||
            multiSelectType === QUES_TYPE.MULTI_SELECT_AUTOTEXT_FILE_UPLOAD ||
            multiSelectType === QUES_TYPE.MULTI_SELECT_AUTOTEXT)
        ) {
          newVal = [
            {
              ...value,
              raw_user_resp: "",
            },
            ...selectedOpt.filter((itm) => itm.value !== NOT_APPLICABLE),
          ];
          setVisibleTxt("");
        } else {
          newVal = [
            ...selectedOpt?.filter((itm) => itm.value !== NOT_APPLICABLE),
            value,
          ];
        }
      } else {
        newVal = selectedOpt.filter((itm) => itm.value !== value.value);
      }
      value.value === ADD_TO_THE_LIST && setOthersEnabled(select);
      setSelectedOpt(newVal);
      onUpdt(newVal);
    };

    const handleClick = (value, select) => {
      if (
        select ||
        !selectedOpt.find((itm) => itm.value === value.value)?.prompt?.files
          ?.length
      ) {
        handleChange(value, select);
      } else {
        setProceed(value);
      }
    };

    const getChkdMarkIfFilesUpload = (defChkdMark, optVal) => {
      if (
        (multiSelectType === QUES_TYPE.MULTI_SELECT_AUTOTEXT_FILE_UPLOAD ||
          multiSelectType === QUES_TYPE.MULTI_SELECT_CHIPS_FILE_UPLOAD) &&
        !selectedOpt.find((itm) => itm.value === optVal)?.prompt?.files?.length
      ) {
        return upload_marked;
      } else {
        return defChkdMark;
      }
    };

    const getHighlightMarkIfFilesUpload = (defHiglightMark, optVal) => {
      if (
        !selectedOpt.find((itm) => itm.value === optVal)?.prompt?.files?.length
      ) {
        return defHiglightMark;
      } else {
        return "0px 1px 5px 2px rgba(19, 136, 8, 0.5)";
      }
    };

    const getOptions = (
      OptionItem = Chips,
      options = responses,
      chips_mark = tick_marked,
      chips_unmark = "",
      highlight_mark = "",
      highlight_unmark = ""
    ) => {
      return (
        <>
          <div className="multiSelectOptWrapper">
            <div className="multiSelectOpt">
              {options
                .filter(
                  (itm) =>
                    itm.value !== ADD_TO_THE_LIST &&
                    itm.value !== NOT_APPLICABLE
                )
                .map((itm, idx) => (
                  <OptionItem
                    imgName={getImgName(quesId, itm.value)}
                    key={idx}
                    onClick={handleClick}
                    checked={isOptValInArr(selectedOpt, itm.value)}
                    itm={itm}
                    marked={
                      isOptValInArr(selectedOpt, itm.value)
                        ? getChkdMarkIfFilesUpload(chips_mark, itm.value)
                        : chips_unmark
                    }
                    highlighted={
                      isOptValInArr(selectedOpt, itm.value)
                        ? getHighlightMarkIfFilesUpload(
                            highlight_mark,
                            itm.value
                          )
                        : highlight_unmark
                    }
                  />
                ))}
            </div>
          </div>
          <div className="multiSelectAddToListNAcontainer">
            {isAddEnable() && (
              <Chips
                key={ADD_TO_THE_LIST}
                onClick={handleClick}
                checked={isOptValInArr(selectedOpt, ADD_TO_THE_LIST)}
                marked={
                  isOptValInArr(selectedOpt, ADD_TO_THE_LIST)
                    ? getChkdMarkIfFilesUpload(chips_mark, ADD_TO_THE_LIST)
                    : chips_unmark
                }
                highlighted={
                  isOptValInArr(selectedOpt, ADD_TO_THE_LIST)
                    ? ""
                    : highlight_unmark
                }
                itm={responses.find((itm) => itm.value === ADD_TO_THE_LIST)}
              />
            )}
            {isNANEnable() && (
              <Chips
                key={NOT_APPLICABLE}
                onClick={handleClick}
                checked={isOptValInArr(selectedOpt, NOT_APPLICABLE)}
                marked={
                  isOptValInArr(selectedOpt, NOT_APPLICABLE) ? chips_mark : ""
                }
                itm={responses.find((itm) => itm.value === NOT_APPLICABLE)}
              />
            )}
          </div>
          {isOthersEnabled && (
            <FreeText
              {...{
                isSkippable: true,
                placeholder: OTHERS_TXT_PLACEHOLDER,
                onSave: () => {},
                onUpdt: handleOthersUpdate,
                saved: getRawValFrmSelected(ADD_TO_THE_LIST),
                isSaveBtnEnable: false,
                highlightIfEmpty: true,
              }}
            />
          )}
        </>
      );
    };

    const handleOthersUpdate = (valArr) => {
      const otherVal = valArr.length > 0 ? valArr[0]["text"] : "";
      const newVal = selectedOpt.map((itm) =>
        itm.value === ADD_TO_THE_LIST
          ? { ...itm, raw_user_resp: otherVal }
          : itm
      );
      setSelectedOpt(newVal);
      onUpdt(newVal);
    };

    return (
      <>
        <Component
          {...{
            ...props,
            quesType: multiSelectType,
            getOptions,
            selectedOpt,
            setSelectedOpt,
            visibleTxt,
            setVisibleTxt,
          }}
        />
        {isSaveBtnEnable && (
          <ActionBtn
            {...{
              isCurQuesUpdt,
              onClick: onSave,
              respArr: selectedOpt,
              btnPrompt: SAVE_PROMPT,
              actionBtn_icn: save_icn,
            }}
          />
        )}
        {canProceed && (
          <ClearResponseDialog {...{ handleChange, canProceed, setProceed }} />
        )}
      </>
    );
  };
  return MultiSelect;
};

export { MultiSelect };
