export const FORM_CTA_BG_COLOR = "#000080";
export const FORM_BG_COLOR = "#ffffff";

export const FORM_FONT_COLOR = "#232F34";
export const CHAT_HEAD_TITLE_FONT_COLOR = "#3F3F3F";
export const CHAT_HEAD_STATUS_FONT_COLOR = "#7C7C7C";
export const GO_BACK_TO_CHAT_FONT_COLOR = "#06A659";
export const FORM_CTA_FONT_COLOR = "white";

export const CHAT_HEAD_STATUS_FONT_SIZE = "1rem"; //12
export const FORM_LANG_CHIPS_FONT_SIZE = "3.9vw"; //14
export const FORM_FONT_SIZE = "4.45vw"; //16
export const CHAT_HEAD_TITLE_FONT_SIZE = "5vw"; //18

export const TILE_LINE_HEIGHT = "3.45vw"; //12.5
export const CHAT_HEAD_STATUS_LINE_HEIGHT = "1.38rem"; //16
export const FORM_LANG_CHIPS_LINE_HEIGHT = "5.3vw"; //19
export const FORM_LINE_HEIGHT = "6.1vw"; //22
export const CHAT_HEAD_TITLE_LINE_HEIGHT = "6.65vw"; //24

export const CHAT_HEAD_STATUS_FONT_WEIGHT = "400";
export const FORM_FONT_WEIGHT = "500";
export const CHAT_HEAD_TITLE_FONT_WEIGHT = "600";
export const FORM_BOLD_FONT_WEIGHT = "700";

export const FONT_FAMILY = "Roboto, sans-serif";

// export const FORM_CTA_BG_COLOR = "#000080";
// export const BODY_FONT_COLOR = "#212529";
// export const BODY_FONT_SIZE = "13px";
// export const CHAT_HEAD_FONT_SIZE = "120%";
// export const FONT_FAMILY = "Roboto,sans-serif";
