import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pen_write from "../../../../assets/images/pen_write.png";
import { setOptScroll } from "../../../../redux/actions/setFormAttribute";
import { getLangOptFromState, getTextFrmPrompt } from "../../../../util/helper";
import save_icn from "../../../../assets/images/save_icn.png";
import { ActionBtn } from "../../../common/actionButton/actionBtn/ActionBtn";
import { SAVE_PROMPT } from "../../../../util/const";
import "./styles.css";

const TextInput = (Component, { validation }) => {
  const TextInput = (props) => {
    const {
      isCurQuesUpdt,
      onSave,
      isSaveBtnEnable = true,
      onUpdt,
      saved="",
      placeholder = "",
      highlightIfEmpty = false,
    } = props;
    const dispatch = useDispatch();
    const [mssg, setMssg] = useState(saved);
    const [err, setErr] = useState("");
    const langOpt = useSelector(getLangOptFromState);
    useEffect(() => {
      dispatch(setOptScroll());
    }, [dispatch]);

    const handlekeyPress = (e) => {
      const code = e.keyCode || e.which;
      const trimMssg = mssg.trim();
      if (code === 13 && !err && trimMssg) {
        onSave([{ text: trimMssg }]);
      }
    };

    const handleChange = (e) => {
      const txtVal = e.target.value;
      setMssg(txtVal);
      const trimMssg = txtVal.trim();
      if (!validation(trimMssg)) {
        setErr("Invalid characters.");
        onUpdt([]);
      } else {
        setErr("");
        onUpdt(trimMssg ? [{ text: trimMssg }] : []);
      }
    };

    const getInputTextDiv = () => (
      <div className="textInput_div">
        <div
          className={`textInput_wrap ${
            err || (highlightIfEmpty && !mssg) ? "errInp_box" : "normalInp_box"
          }`}
        >
          <img className="textInput_pen_icon" src={pen_write} alt="pen_write" />
          <input
            className={`textInput ${err ? "errInp" : "normalInp"}`}
            autoFocus
            autoComplete="off"
            placeholder={getTextFrmPrompt(placeholder, langOpt.value)}
            value={mssg}
            onChange={handleChange}
            onKeyPress={handlekeyPress}
          />
        </div>
        <div className="errMssg">{err}</div>
      </div>
    );

    return (
      <>
        <Component {...{ getInputTextDiv }} />
        {isSaveBtnEnable && (
          <ActionBtn
            {...{
              ...props,
              isCurQuesUpdt,
              onClick: onSave,
              respArr: [{ text: mssg.trim() }],
              btnPrompt: SAVE_PROMPT,
              actionBtn_icn: save_icn,
            }}
          />
        )}
      </>
    );
  };
  return TextInput;
};

export { TextInput };
