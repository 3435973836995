// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatBody {
    height: 100vh;
}

.chatArea {
  display: flex;
  flex-direction: column;
  padding: 0 6%;
  overflow: auto;
  height: calc(100vh - 5rem);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media (orientation:landscape){
}`, "",{"version":3,"sources":["webpack://./src/components/form/formChat/chatBody/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,4CAA4C;EAE5C,mBAAmB;AACrB;;AAEA,WAAW;AACX;EAEE,mBAAmB;EACnB,oCAAoC;EACpC,4CAA4C;AAC9C;;AAEA,oBAAoB;AACpB;EACE,oCAAoC;AACtC;;AAEA;AACA","sourcesContent":[".chatBody {\n    height: 100vh;\n}\n\n.chatArea {\n  display: flex;\n  flex-direction: column;\n  padding: 0 6%;\n  overflow: auto;\n  height: calc(100vh - 5rem);\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n  -webkit-border-radius: 10px;\n  border-radius: 10px;\n}\n \n/* Handle */\n::-webkit-scrollbar-thumb {\n  -webkit-border-radius: 10px;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.3);\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: rgba(255, 255, 255, 0.3);\n}\n\n@media (orientation:landscape){\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
