import { useSelector } from "react-redux";
import { NAME_PLACE_HOLDER } from "../../../../util/const";
import {
  getLangOptFromState,
  getPInitialsFrmState,
  getTextFrmPrompt,
} from "../../../../util/helper";
import { TextBox } from "../../../common/textBox/TextBox";
import "./styles.css";

const ChatQueueBlock = (props) => {
  const langOpt = useSelector(getLangOptFromState);
  const pInitials = useSelector(getPInitialsFrmState);

  const getDisplayPrompt = () => {
    const propmtTxt = getTextFrmPrompt(props.prompt.text, langOpt.value);
    return propmtTxt.replace(
      NAME_PLACE_HOLDER[langOpt.value],
      pInitials["pNameInitls"]
    );
  };

  return (
    <div
      className={`chatBlock ${
        props.src === "bot" ? "chatBlock_left" : "chatBlock_right"
      }`}
    >
      <TextBox text={getDisplayPrompt()} />
    </div>
  );
};

export { ChatQueueBlock };
