import { SET_INITIALS, SET_LANG_REQUEST, SET_OPT_SCROLLABLE } from "../types";

export const setLang = (langOpt) => ({
  type: SET_LANG_REQUEST,
  payload: { lang_id: langOpt.value },
});

export const setPInitials = (pInitials) => ({
  type: SET_INITIALS,
  payload: pInitials,
});

export const setOptScroll = () => ({
  type: SET_OPT_SCROLLABLE,
});
