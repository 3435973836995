import {
  CREATE_CHAT_SUCCESS,
  FINISH_FORM_SUCCESS,
  RESET_CHAT_SUCCESS,
  UPDT_CUR_RESP,
} from "../types";

const initialState = {
  formResponses: {},
  resp_model: {},
};

const formResponses = (state = initialState, action) => {
  const { type, payload } = action;
  let { formResponses } = state;
  switch (type) {
    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        formResponses: payload.formResp,
      };
    case RESET_CHAT_SUCCESS:
      return {
        formResponses: {},
      };
    case FINISH_FORM_SUCCESS:
      return {
        ...state,
        resp_model: payload,
      };
    case UPDT_CUR_RESP:
      return {
        ...state,
        formResponses: {
          ...formResponses,
          ...{ [payload.qid]: payload.values },
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export { formResponses };
