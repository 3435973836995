import { RESET_PROMPT } from "../../../../util/const";
import reset_chat from "../../../../assets/images/reset_chat.png";
import { getLangOptFromState, getTextFrmPrompt } from "../../../../util/helper";
import { useSelector } from "react-redux";
import "./styles.css";

const ResetBtn = ({ isResetEnable, toggleResetDialogBox }) => {
  const langOpt = useSelector(getLangOptFromState);
  return (
    <div className="resetDiv">
      {isResetEnable() && (
        <>
          <img
            onClick={toggleResetDialogBox}
            className="resetDiv_img"
            src={reset_chat}
            alt="reset_chat"
          />
          <span onClick={toggleResetDialogBox} className="resetDiv_txt">
            {getTextFrmPrompt(RESET_PROMPT, langOpt.value)}
          </span>
        </>
      )}
    </div>
  );
};

export { ResetBtn };
