import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, put, call, select, all } from "redux-saga/effects";
import { getSessionIDFromState } from "../../util/helper";
import {
  RESET_CHAT_FAIL,
  RESET_CHAT_REQUEST,
  RESET_CHAT_SUCCESS,
  SKIP_FORM_FAIL_SAFE,
} from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const resetChatCallback = (session_id) => {
  return axios.request({
    method: "PUT",
    headers: { "x-access-token": session_id },
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/reset`,
  });
};

function* resetChat({ toggleResetDialogBox }) {
  try {
    const session_id = yield select(getSessionIDFromState);
    const res = yield call(resetChatCallback, session_id);
    if (res.data) {
      toggleResetDialogBox();
      yield put({ type: RESET_CHAT_SUCCESS });
    } else {
      yield all([
        put({ type: RESET_CHAT_FAIL, payload: res.error }),
        put({
          type: SKIP_FORM_FAIL_SAFE,
          payload: { err: res.error, errInfo: "resetChatFailed" },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: RESET_CHAT_FAIL, payload: error }),
      put({
        type: SKIP_FORM_FAIL_SAFE,
        payload: { err: error, errInfo: "resetChatFailed" },
      }),
    ]);
  }
}

function* reqFormReset() {
  yield takeEvery(RESET_CHAT_REQUEST, resetChat);
}

export { reqFormReset };
