import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Form from "./components/form/Form";
import FormErrorBoundary from "./components/form/formErrorBoundary/FormErrorBoundary";

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <FormErrorBoundary>
            <Form />
          </FormErrorBoundary>
        </Router>
      </div>
    </Provider>
  );
};

export default App;
