import { useEffect } from "react";
import { useSelector } from "react-redux";
import serviceUnavailable from "../../../assets/images/service_unavailable.png";
import { getRespModel, sendMessage } from "../../../util/helper";
import "./styles.css";

const FormFinished = () => {
  const resp_model = useSelector(getRespModel);
  useEffect(() => {
    setTimeout(() => {
      sendMessage({ resp_msg: "Form Finished", resp_model: resp_model });
    }, 5000);
  });

  return (
    <div className="formFinished">
      <img
        className="formFinished_img"
        src={serviceUnavailable}
        alt="formFinished_img"
      />
      <p className="formFinished_p">
        Form is finished and all responses saved.
      </p>
    </div>
  );
};

export { FormFinished };
