import { useSelector } from "react-redux";
import serviceUnavailable from "../../../assets/images/service_unavailable.png";
import whiteNext from "../../../assets/images/white_next.png";
import {
  FORM_BOLD_FONT_WEIGHT,
  FORM_CTA_BG_COLOR,
  FORM_CTA_FONT_COLOR,
  FORM_LANG_CHIPS_FONT_SIZE,
  FORM_LANG_CHIPS_LINE_HEIGHT,
} from "../../../res/resource";
import { dispMssg } from "../../../util/const";
import { getLangOptFromState } from "../../../util/helper";
import "./styles.css";

const FormIntro = ({ setPage }) => {
  const langOpt = useSelector(getLangOptFromState);
  const handleClick = () => {
    setPage(2);
  };

  return (
    <div className="formIntro">
      <div className="formIntro_Body">
        <img
          className="formIntro_img"
          src={serviceUnavailable}
          alt="formIntro_img"
        />
        <div className="formIntro_mssg">
          <p className="formIntro_p">{dispMssg.intro_head[langOpt.value]}</p>
          <p className="formIntro_p">{dispMssg.intro_mssg[langOpt.value]}</p>
        </div>
      </div>
      <div
        className="formIntro_next"
        style={{
          fontSize: FORM_LANG_CHIPS_FONT_SIZE,
          lineHeight: FORM_LANG_CHIPS_LINE_HEIGHT,
          fontWeight: FORM_BOLD_FONT_WEIGHT,
          backgroundColor: FORM_CTA_BG_COLOR,
          color: FORM_CTA_FONT_COLOR,
        }}
        onClick={handleClick}
      >
        <p className="formIntro_p">{"Let’s talk about your health"}</p>
        <img
          className="formIntro_next_img"
          src={whiteNext}
          alt="formIntro_next_img"
        />
      </div>
    </div>
  );
};

export { FormIntro };
