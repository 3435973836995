import { QUES_TYPE } from "../../../../../util/const";
import { TileA } from "../../../../common/tile_sm/TileA";
import { SingleSelect } from "../SingleSelect";
import "./styles.css";

const SingleSelectTiles = ({ getOptions, responses }) => {
  return <>{getOptions(TileA)}</>;
};

export default SingleSelect(SingleSelectTiles, QUES_TYPE.SINGLE_SELECT_TILES);
