import serviceUnavailable from "../../../assets/images/service_unavailable.png";
import SingleSelectChips from "../../fields/userInput/singleSelect/singleSelectChips/SingleSelectChips";
import { listOfLang } from "../../../util/const";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../../../redux/actions/setFormAttribute";
import {
  getLangOptFromState,
  getPInitialsFrmState,
} from "../../../util/helper";
import {
  FORM_BOLD_FONT_WEIGHT,
  FORM_CTA_BG_COLOR,
  FORM_CTA_FONT_COLOR,
  FORM_LANG_CHIPS_FONT_SIZE,
  FORM_LANG_CHIPS_LINE_HEIGHT,
} from "../../../res/resource";
import "./styles.css";

const FormLang = ({ setPage }) => {
  const dispatch = useDispatch();
  const langOpt = useSelector(getLangOptFromState);
  const pInitials = useSelector(getPInitialsFrmState);

  const handleClick = () => {
    setPage(1);
  };
  const handleChngLang = (newlangOpt) => {
    if (newlangOpt[0].value !== langOpt.value) {
      dispatch(setLang(newlangOpt[0]));
    }
  };

  return (
    <div className="formLang">
      <div className="formLang_Body">
        <img
          className="formLang_img"
          src={serviceUnavailable}
          alt={"formLang_img"}
        />
        <div className="formLang_mssg">
          <div className="formLang_greet">
            <p>
              Hey{" "}
              {pInitials["pNameInitls"] ? pInitials["pNameInitls"] : "Anita"}{" "}
              👋,
            </p>
            <p>
              Before you start sharing your Health issues, please select the
              language you want to have this conversation in.
            </p>
          </div>
        </div>
        <div
          className="formLang_opt"
          style={{
            fontSize: FORM_LANG_CHIPS_FONT_SIZE,
            lineHeight: FORM_LANG_CHIPS_LINE_HEIGHT,
          }}
        >
          <SingleSelectChips
            responses={listOfLang}
            saved={langOpt}
            onUpdt={handleChngLang}
            onSave={() => {}}
            rowAlign
          />
        </div>
      </div>
      <div
        className="formLang_next"
        style={{
          fontSize: FORM_LANG_CHIPS_FONT_SIZE,
          lineHeight: FORM_LANG_CHIPS_LINE_HEIGHT,
          fontWeight: FORM_BOLD_FONT_WEIGHT,
          backgroundColor: FORM_CTA_BG_COLOR,
          color: FORM_CTA_FONT_COLOR,
        }}
        onClick={handleClick}
      >
        <p className="formLang_p">Save Language Choice</p>
      </div>
    </div>
  );
};

export { FormLang };
