import { useState, useRef, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import { compressImage, dataURItoFile } from "../../../util/helper";
import "./styles.css";
import { FILE_MAX_SIZE } from "../../../util/const";

const CameraWrap = ({
  handleChange,
  toggleCamera,
  toggleFileUploadModel,
  togglePreview,
  removeFile,
}) => {
  const webcamRef = useRef(null);
  const [isSwitchCameraAvailable, setIsSwitchCameraAvailable] = useState(false);
  const [isCameraReady, setCameraReady] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [orientation, setOrientation] = useState("");

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(
        window.screen.width > window.screen.height ? "landscape" : "portrait"
      );
    };
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  const openCamera = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    setIsSwitchCameraAvailable(videoDevices.length > 1);
  };

  const handleUserMedia = () => {
    setCameraReady(true);
  };

  useEffect(() => {
    openCamera();
  }, []);

  const captureImage = () => {
    try {
      if (webcamRef.current?.state?.hasUserMedia) {
        let imageSrc = webcamRef.current.getScreenshot();
        let imgCapture = dataURItoFile(imageSrc, Date.now());
        if (imgCapture.size / 1000 <= FILE_MAX_SIZE) {
          handleChange([imgCapture]);
          togglePreview({ itm: imgCapture, removeFile, title: Date.now() });
          closeCamera();
        } else {
          compressImage(imgCapture)
            .then((outputImage) => {
              handleChange([outputImage]);
              togglePreview({
                itm: outputImage,
                removeFile,
                title: Date.now(),
              });
              closeCamera();
            })
            .catch();
        }
      }
    } catch (error) {}
  };

  const closeCamera = (err) => {
    console.log(err);
    setIsSwitchCameraAvailable(false);
    toggleCamera();
  };

  const switchCamera = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === "user" ? "environment" : "user"
    );
  }, []);

  return (
    <div className="cameraWrapDialog">
      <div className="cameraWrapDialogBackground" />
      <div className="cameraWrapDialogBox">
        <Webcam
          audio={false}
          ref={webcamRef}
          className="cameraWrapDialog_feed"
          videoConstraints={{
            facingMode: facingMode,
            width: orientation === "landscape" ? 1920 : 1080,
            height: orientation === "portrait" ? 1920 : 1080,
          }}
          screenshotQuality={1}
          onUserMedia={handleUserMedia}
          onUserMediaError={closeCamera}
        />
        <div className="cameraWrapDialog_action">
          <span
            className={`cameraWrapDialog_action_btn ${
              isCameraReady ? "camera_btn_left" : "camera_btn_left_disabled"
            }`}
            onClick={captureImage}
          >
            {"Capture"}
          </span>
          {isSwitchCameraAvailable && (
            <span
              className="cameraWrapDialog_action_btn"
              onClick={switchCamera}
            >
              {"Switch"}
            </span>
          )}
          <span
            className="cameraWrapDialog_action_btn camera_btn_right"
            onClick={closeCamera}
          >
            {"Cancel"}
          </span>
        </div>
      </div>
    </div>
  );
};

export { CameraWrap };
