import closeFormDialog_cancel from "../../../assets/images/closeFormDialog_cancel.png";
import closeFormDialog_icn from "../../../assets/images/closeFormDialog_icn.png";
import { useDispatch, useSelector } from "react-redux";
import { resetChat } from "../../../redux/actions/updtChat";
import {
  CANCEL_PROMPT,
  RESET_FORM_DIALOG_MSSG,
  RESET_PROMPT,
} from "../../../util/const";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import "./styles.css";

const CloseFormDialog = ({ toggleResetDialogBox }) => {
  const dispatch = useDispatch();
  const langOpt = useSelector(getLangOptFromState);
  const handleResetChat = () => {
    dispatch(resetChat(toggleResetDialogBox));
  };

  return (
    <div className="closeFormDialog">
      <div className="closeFormDialogBackground" />
      <div className="closeFormDialogBox">
        <img
          className="closeFormDialog_cancel"
          src={closeFormDialog_cancel}
          alt={"closeFormDialog_cancel"}
          onClick={toggleResetDialogBox}
        />
        <img
          className="closeFormDialog_icn"
          src={closeFormDialog_icn}
          alt={"closeFormDialog_icn"}
        />
        <p className="closeFormDialog_mssg">
          {getTextFrmPrompt(RESET_FORM_DIALOG_MSSG, langOpt.value)}
        </p>
        <div className="closeFormDialog_action">
          <span
            className="closeFormDialog_action_btn close_form_btn_left"
            onClick={handleResetChat}
          >
            {getTextFrmPrompt(RESET_PROMPT, langOpt.value)}
          </span>
          <span
            className="closeFormDialog_action_btn close_form_btn_right"
            onClick={toggleResetDialogBox}
          >
            {getTextFrmPrompt(CANCEL_PROMPT, langOpt.value)}
          </span>
        </div>
      </div>
    </div>
  );
};

export { CloseFormDialog };
