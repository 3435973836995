import { useSelector } from "react-redux";
import serviceUnavailable from "../../../assets/images/service_unavailable.png";
import {
  FORM_ERR_PROMPT_DESC,
  FORM_ERR_PROMPT_HEAD,
} from "../../../util/const";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import "./styles.css";

const FormErr = () => {
  const langOpt = useSelector(getLangOptFromState);
  return (
    <div className="formErr">
      <img className="formErr_img" src={serviceUnavailable} alt="formErr_img" />
      <div className="formErr_p">
        <p>{getTextFrmPrompt(FORM_ERR_PROMPT_HEAD, langOpt.value)}</p>
        <p>{getTextFrmPrompt(FORM_ERR_PROMPT_DESC, langOpt.value)}</p>
      </div>
    </div>
  );
};

export { FormErr };
