import { QUES_TYPE } from "../../../../../util/const";
import { TileA } from "../../../../common/tile_sm/TileA";
import { MultiSelect } from "../MultiSelect";
import "./styles.css";

const MultiSelectTiles = ({ getOptions }) => {
  return <>{getOptions(TileA)}</>;
};

export default MultiSelect(MultiSelectTiles, QUES_TYPE.MULTI_SELECT_TILES);
