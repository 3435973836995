import {
  BACK_QUES_REQUEST,
  SUBMIT_RESP_REQUEST,
  GET_CUR_QUES_REQUEST,
  RESET_CHAT_REQUEST,
} from "../types";

export const saveResponse = (id, value = []) => {
  let formData = new FormData();
  formData.append("qid", id);
  value.forEach((itm) => {
    formData.append("values", JSON.stringify(itm));
    itm.prompt?.files?.forEach((fileitm) => {
      formData.append("files", fileitm);
    });
  });

  return {
    type: SUBMIT_RESP_REQUEST,
    payload: formData,
  };
};

export const reqFirstQues = () => ({ type: GET_CUR_QUES_REQUEST });

export const reqPrevQues = () => ({ type: BACK_QUES_REQUEST });

export const resetChat = (toggleResetDialogBox) => ({
  type: RESET_CHAT_REQUEST,
  toggleResetDialogBox: toggleResetDialogBox,
});
