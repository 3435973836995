import { useEffect } from "react";
import "./styles.css";

const AlertFormDialog = ({ header, toggleSuccessAlert, imgSrc, footer }) => {
  useEffect(() => {
    setTimeout(() => {
      toggleSuccessAlert();
    }, 2000);
  }, [toggleSuccessAlert]);
  return (
    <div className="alertFormDialog">
      <div className="alertDialogBackground" />
      <div className="alertDialogBox">
        <h3>{header}</h3>
        <img className="alert_itm" src={imgSrc} alt="preview_file" />
        <h3>{footer}</h3>
      </div>
    </div>
  );
};

export { AlertFormDialog };
