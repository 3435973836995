// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filesUploadBackground {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.filesUploadBox {
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 1.25rem;
    padding: 7.5px;
    max-height: 95vh;
    width: 500px;
    max-width: 95vw;
}
.filesUpload_preview{
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.filesUpload_preview_container{
    width: 100%;
}

.wrapper_available{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(255, 151, 60, 1);
    margin: 0 1% 1% 0;
    padding: 1%;
    background-color: rgba(255, 249, 242, 1);
    color: rgba(255, 151, 60, 1);
}

.wrapper_available .thumbnailPreview{
    margin-bottom: 0;
}

.wrapper_h4{
    width: 100%;
    margin-bottom: 2.5%;
    display: flex;
    justify-content: space-between;
}

.wrapper_actionBtn{
    height: 0.5rem;
    object-fit: contain;
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/fields/userInput/filesUpload/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,UAAU;AACd;;AAEA;IACI,eAAe;IACf,UAAU;IACV,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,uCAAuC;IACvC,iBAAiB;IACjB,WAAW;IACX,wCAAwC;IACxC,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".filesUploadBackground {\n    position: fixed;\n    top: 0;\n    left: 0;\n    opacity: 0.7;\n    background-color: #000000;\n    width: 100vw;\n    height: 100vh;\n    z-index: 1;\n}\n\n.filesUploadBox {\n    position: fixed;\n    z-index: 1;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: #FFFFFF;\n    border-radius: 1.25rem;\n    padding: 7.5px;\n    max-height: 95vh;\n    width: 500px;\n    max-width: 95vw;\n}\n.filesUpload_preview{\n    height: fit-content;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.filesUpload_preview_container{\n    width: 100%;\n}\n\n.wrapper_available{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-radius: 5px;\n    border: 1px solid rgba(255, 151, 60, 1);\n    margin: 0 1% 1% 0;\n    padding: 1%;\n    background-color: rgba(255, 249, 242, 1);\n    color: rgba(255, 151, 60, 1);\n}\n\n.wrapper_available .thumbnailPreview{\n    margin-bottom: 0;\n}\n\n.wrapper_h4{\n    width: 100%;\n    margin-bottom: 2.5%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.wrapper_actionBtn{\n    height: 0.5rem;\n    object-fit: contain;\n    margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
