// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.35rem;
}

.resetDiv_img {
    width:0.8rem;
    height: 0.8rem;
    object-fit: contain;
    cursor: pointer;
}

.resetDiv_txt {
    cursor: pointer;
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/common/actionButton/resetBtn/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".resetDiv {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    column-gap: 0.35rem;\n}\n\n.resetDiv_img {\n    width:0.8rem;\n    height: 0.8rem;\n    object-fit: contain;\n    cursor: pointer;\n}\n\n.resetDiv_txt {\n    cursor: pointer;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
