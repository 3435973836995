// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatBlock {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 70%;
}

.chatBlock_left {
    margin-top: 1.5rem;
    border-radius: 0 2.04rem 2.04rem  2.04rem;
    background-color: #FF9933;
    color: #ffffff;
    padding: 1.84rem 1.69rem
}

.chatBlock_right {
    margin-top: 1.38rem;
    float: right;
    border-radius:  2.04rem 2.04rem 0 2.04rem ;
    background-color:#92DCE5;
    color: #000000;
    padding: 1.84rem 1.69rem;
}`, "",{"version":3,"sources":["webpack://./src/components/fields/chatQueue/chatQueueBlock/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yCAAyC;IACzC,yBAAyB;IACzB,cAAc;IACd;AACJ;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,0CAA0C;IAC1C,wBAAwB;IACxB,cAAc;IACd,wBAAwB;AAC5B","sourcesContent":[".chatBlock {\n    width: fit-content;\n    max-width: 70%;\n}\n\n.chatBlock_left {\n    margin-top: 1.5rem;\n    border-radius: 0 2.04rem 2.04rem  2.04rem;\n    background-color: #FF9933;\n    color: #ffffff;\n    padding: 1.84rem 1.69rem\n}\n\n.chatBlock_right {\n    margin-top: 1.38rem;\n    float: right;\n    border-radius:  2.04rem 2.04rem 0 2.04rem ;\n    background-color:#92DCE5;\n    color: #000000;\n    padding: 1.84rem 1.69rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
