import { useSelector } from "react-redux";
import { SAVE_PROMPT } from "../../../util/const";
import { getSessionIDFromState, getTextFrmPrompt } from "../../../util/helper";
import def_file_prev from "../../../assets/images/def_file_prev.webp";
import { useEffect, useRef, useState } from "react";
import "./styles.css";
import axios from "axios";

const ImagePreview = ({ itm, removeFile, title, togglePreview, langOpt }) => {
  const session_id = useSelector(getSessionIDFromState);
  const prevImgRef = useRef(null);
  const handleRemove = () => {
    removeFile(title);
    togglePreview();
  };
  const onImgErr = () => {
    prevImgRef.current.setAttribute("src", def_file_prev);
  };
  const [imgSrc, setImgSrc] = useState(null);
  useEffect(() => {
    if (itm instanceof File) {
      setImgSrc(URL.createObjectURL(itm));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/get_blob?token=${session_id}&blob_path=${itm}`
        )
        .then((response) => {
          setImgSrc("data:image/png;base64," + response.data);
        })
        .catch(() => {});
    }
  }, [itm, session_id]);

  return (
    <div className="imagePreviewDialog">
      <div className="imagePreviewDialogBackground" />
      <div className="imagePreviewDialogBox">
        <img
          className="imagePreview_itm"
          src={imgSrc}
          alt="preview_file"
          onError={onImgErr}
        />
        <div className="imagePreviewDialog_action">
          <span
            className="imagePreviewDialog_action_btn preview_btn_left"
            onClick={togglePreview}
          >
            {getTextFrmPrompt(SAVE_PROMPT, langOpt.value)}
          </span>
          <span
            className="imagePreviewDialog_action_btn preview_btn_right"
            onClick={handleRemove}
          >
            {"Remove"}
          </span>
        </div>
      </div>
    </div>
  );
};

export { ImagePreview };
