// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile_small {
    width: 29.5%;
    max-width: 9rem;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 0.7rem 0.7rem 0;    
    cursor: pointer;
}

.tile_small_box{
    position: relative;
    height: 8.5rem;
}

.tile_sm_img {
    width: 100%;
    height: 10rem;
    top: 0;
    border:solid 0.1rem #C1C1C1;
    border-radius: 1.007rem;
    object-fit: cover;
}

.tile_sm_status {
    width: 21.5%;
    height: 22.5%;
    position: absolute;
    top: 5%;
    right: 5%;
    object-fit: cover;
}

.tile_sm_name {
    width: 100%;
    border-radius: 0.92rem;
    position: relative;
}

.tile_sm_name_p {
    padding: 0.75rem;
    line-height: normal;
    margin: 0;
}
.tile_small ::-webkit-scrollbar {
    width: 0;
}

.tile_sm_unchecked {
    border: solid 0.1rem #C1C1C1;
    background-color: #ffffff;
    color: black;
}
.tile_sm_checked {
    border: solid 0.1rem #00127E;
    background-color: #D8DCEE;
    color:black;
}

@media (orientation: landscape){
    .tile_small{
        width: 9rem;
    }
}

@media screen and (max-width: 345px){
    .tile_small {
        width: 9rem;
        padding-right: 5px;
        margin: 0 auto 0.7rem;  
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tile_sm/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,wBAAmB;IAAnB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,MAAM;IACN,2BAA2B;IAC3B,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,QAAQ;AACZ;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;QACX,kBAAkB;QAClB,qBAAqB;IACzB;AACJ","sourcesContent":[".tile_small {\n    width: 29.5%;\n    max-width: 9rem;\n    height: fit-content;\n    margin: 0 0.7rem 0.7rem 0;    \n    cursor: pointer;\n}\n\n.tile_small_box{\n    position: relative;\n    height: 8.5rem;\n}\n\n.tile_sm_img {\n    width: 100%;\n    height: 10rem;\n    top: 0;\n    border:solid 0.1rem #C1C1C1;\n    border-radius: 1.007rem;\n    object-fit: cover;\n}\n\n.tile_sm_status {\n    width: 21.5%;\n    height: 22.5%;\n    position: absolute;\n    top: 5%;\n    right: 5%;\n    object-fit: cover;\n}\n\n.tile_sm_name {\n    width: 100%;\n    border-radius: 0.92rem;\n    position: relative;\n}\n\n.tile_sm_name_p {\n    padding: 0.75rem;\n    line-height: normal;\n    margin: 0;\n}\n.tile_small ::-webkit-scrollbar {\n    width: 0;\n}\n\n.tile_sm_unchecked {\n    border: solid 0.1rem #C1C1C1;\n    background-color: #ffffff;\n    color: black;\n}\n.tile_sm_checked {\n    border: solid 0.1rem #00127E;\n    background-color: #D8DCEE;\n    color:black;\n}\n\n@media (orientation: landscape){\n    .tile_small{\n        width: 9rem;\n    }\n}\n\n@media screen and (max-width: 345px){\n    .tile_small {\n        width: 9rem;\n        padding-right: 5px;\n        margin: 0 auto 0.7rem;  \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
