import { GET_SNOMED_FINDINGS_PASS } from "../types";

const initialState = {
  snomedFindings: [],
};

const snomedFindings = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SNOMED_FINDINGS_PASS:
      return {
        ...state,
        snomedFindings: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export { snomedFindings };
