import { all } from "redux-saga/effects";
import { getSnomedFindings } from "./getSnomedFindings";
import { initForm } from "./initForm";
import { reqCurQues } from "./reqCurQues";
import { reqPrevQues } from "./reqPrevQues";
import { saveResponse } from "./saveResponse";
import { reqFormReset } from "./reqFormReset";
import { updateLang } from "./updateLang";
import { skipFormFailSafe } from "./skipFormFailSafe";

function* rootSaga() {
  yield all([
    initForm(),
    reqCurQues(),
    saveResponse(),
    reqPrevQues(),
    reqFormReset(),
    getSnomedFindings(),
    updateLang(),
    skipFormFailSafe(),
  ]);
}

export { rootSaga };
