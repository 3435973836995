import { connect } from "react-redux";
import { PrevBtn } from "../../../common/actionButton/prevBtn/PrevBtn";
import { ResetBtn } from "../../../common/actionButton/resetBtn/ResetBtn";
import { isRespArrSkippable, isRespArrValid } from "../../../../util/helper";
import NextBtn from "../../../common/actionButton/nextBtn/NextBtn";
import { CHAT_HEAD_TITLE_FONT_COLOR } from "../../../../res/resource";
import { useCallback } from "react";
import "./styles.css";

const ChatNavAction = ({
  toggleResetDialogBox,
  chatQ,
  currQues,
  formResp,
  isMoveEnabled,
  isCurQuesUpdt,
  updtCurQues,
}) => {
  const isNextEnable = useCallback(() => {
    return isMoveEnabled &&
      currQues &&
      ((currQues.skippable === "True" &&
        isRespArrSkippable(formResp[currQues.qid])) ||
        (!isCurQuesUpdt &&
          currQues.skippable !== "True" &&
          isRespArrValid(formResp[currQues.qid])))
      ? true
      : false;
  }, [currQues, formResp, isMoveEnabled, isCurQuesUpdt]);

  const isBackEnable = useCallback(() => {
    return isMoveEnabled &&
      chatQ.length !== 0 &&
      chatQ.find((itm) => itm.src === "user")
      ? true
      : false;
  }, [chatQ, isMoveEnabled]);

  return (
    <div
      className="chatNavAction"
      style={{ color: CHAT_HEAD_TITLE_FONT_COLOR }}
    >
      <PrevBtn {...{ isBackEnable, updtCurQues }} />
      <ResetBtn {...{ isResetEnable: isBackEnable, toggleResetDialogBox }} />
      <NextBtn
        {...{
          isNextEnable,
          currQues,
          formResp,
          updtCurQues,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  chatQ: state.formChat.chatQ,
  currQues: state.formChat.currQues,
  formResp: state.formResponses.formResponses,
});

export default connect(mapStateToProps)(ChatNavAction);
