// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagePreviewDialogBackground {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}

.imagePreviewDialogBox {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 1.25rem;
    padding: 2%;
    max-height: 95vh;
    max-width: 95vw;
}

.imagePreview_itm{
    max-height: calc(95vh - 5rem);
    width: auto;
}

.imagePreviewDialog_action {
    display: flex;
    justify-content: center;
    padding: 1% 2.5% 0;
    white-space: nowrap;
}

.imagePreviewDialog_action_btn {
    text-align: center;
    background: transparent;
    padding: .8rem 2rem;
    cursor: pointer;
    border-radius: 1.15rem;
    border: 1px solid #00127E;
}

.preview_btn_left {
    background: #00127E;
    color: white;
    margin-right: 5%;
}

.preview_btn_right {
    border: 1px solid #FF4425;
    color: #FF4425;
    margin-left: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ImagePreview/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,UAAU;AACd;;AAEA;IACI,eAAe;IACf,UAAU;IACV,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".imagePreviewDialogBackground {\n    position: fixed;\n    top: 0;\n    left: 0;\n    opacity: 0.7;\n    background-color: #000000;\n    width: 100vw;\n    height: 100vh;\n    z-index: 2;\n}\n\n.imagePreviewDialogBox {\n    position: fixed;\n    z-index: 2;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    display: flex;\n    flex-direction: column;\n    background: #FFFFFF;\n    border-radius: 1.25rem;\n    padding: 2%;\n    max-height: 95vh;\n    max-width: 95vw;\n}\n\n.imagePreview_itm{\n    max-height: calc(95vh - 5rem);\n    width: auto;\n}\n\n.imagePreviewDialog_action {\n    display: flex;\n    justify-content: center;\n    padding: 1% 2.5% 0;\n    white-space: nowrap;\n}\n\n.imagePreviewDialog_action_btn {\n    text-align: center;\n    background: transparent;\n    padding: .8rem 2rem;\n    cursor: pointer;\n    border-radius: 1.15rem;\n    border: 1px solid #00127E;\n}\n\n.preview_btn_left {\n    background: #00127E;\n    color: white;\n    margin-right: 5%;\n}\n\n.preview_btn_right {\n    border: 1px solid #FF4425;\n    color: #FF4425;\n    margin-left: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
