import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { ChatBody } from "./chatBody/ChatBody";
import { CloseFormDialog } from "../../fields/closeFormDialog/CloseFormDialog";
import { sendMessage } from "../../../util/helper";
import { FormLoader } from "../../fields/formLoader/FormLoader";
import "./styles.css";

const FormChat = ({
  isQuesLoading,
  isFormInitLoading,
  isSearchLoading,
  formFinished,
  respModel,
}) => {
  const [resetChatDialogBox, setResetChatDialogBox] = useState(false);

  const getFormLoadingStatus = () =>
    isFormInitLoading || isQuesLoading || isSearchLoading;

  useEffect(() => {
    if (formFinished) {
      sendMessage({ resp_msg: "Form Finished", resp_model: respModel });
    }
  }, [formFinished, respModel]);

  const toggleResetDialogBox = useCallback(() => {
    setResetChatDialogBox((prevClosedDialog) => !prevClosedDialog);
  }, []);

  return (
    <div className="formChat">
      <ChatBody {...{ toggleResetDialogBox, isQuesLoading, isSearchLoading }} />
      {resetChatDialogBox && <CloseFormDialog {...{ toggleResetDialogBox }} />}
      {getFormLoadingStatus() && <FormLoader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isQuesLoading: state.formStatus.ques_loading,
  isSearchLoading: state.formStatus.search_loading,
  isFormInitLoading: state.formStatus.form_loading,
  formFinished: state.formStatus.form_finished,
  respModel: state.formResponses.resp_model,
});

export default connect(mapStateToProps)(FormChat);
