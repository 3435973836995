// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips {
    margin: 0 0.75rem 0.75rem 0;
    padding: 0.65rem 1.8rem; 
    border-radius: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: black;
    width: -moz-fit-content;
    width: fit-content;
}

.chips_unchecked {
    border: solid 0.1rem #C1C1C1;
    background-color: #ffffff;
}

.chips_checked {
    border: solid 0.1rem #00127E;
    background-color: #D8DCEE;
}

.chips_checked.chips_marked,  .chips_unchecked.chips_marked{
    padding:0.65rem 0.95rem;
}

.chip_tick_mark {
    width: 0.75rem;  
    height: 0.75rem;
    margin-left: 0.94rem;
    object-fit: contain;
}

.chip_plus_mark {
    width: 0.75rem;  
    height: 0.75rem;
    margin-left: 0.94rem;
    object-fit: contain;
}

.chips_newline {
    flex-basis: 100%;
    height: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/chips/styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".chips {\n    margin: 0 0.75rem 0.75rem 0;\n    padding: 0.65rem 1.8rem; \n    border-radius: 3rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n    color: black;\n    width: fit-content;\n}\n\n.chips_unchecked {\n    border: solid 0.1rem #C1C1C1;\n    background-color: #ffffff;\n}\n\n.chips_checked {\n    border: solid 0.1rem #00127E;\n    background-color: #D8DCEE;\n}\n\n.chips_checked.chips_marked,  .chips_unchecked.chips_marked{\n    padding:0.65rem 0.95rem;\n}\n\n.chip_tick_mark {\n    width: 0.75rem;  \n    height: 0.75rem;\n    margin-left: 0.94rem;\n    object-fit: contain;\n}\n\n.chip_plus_mark {\n    width: 0.75rem;  \n    height: 0.75rem;\n    margin-left: 0.94rem;\n    object-fit: contain;\n}\n\n.chips_newline {\n    flex-basis: 100%;\n    height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
