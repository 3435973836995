// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formIntro {
    margin: 0 3.5vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.formIntro_Body{
    margin: 16vh 0 0 0;
}

.formIntro_next{
    text-align: center;
    padding: 0 8vw;
    margin: 0 auto 5.5vh;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.formIntro_mssg {
    width: 70vw;
    text-align: center;
    margin: 0 auto;
}

.formIntro_img {
    width: 67vw;
    height: 37.5vh;
    object-fit: contain;
}

.formIntro_next_img {
    width: 7vw;
    height: 4vh;
    margin: 0 0 0 3.5vw;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/form/formIntro/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,oBAAoB;IACpB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".formIntro {\n    margin: 0 3.5vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.formIntro_Body{\n    margin: 16vh 0 0 0;\n}\n\n.formIntro_next{\n    text-align: center;\n    padding: 0 8vw;\n    margin: 0 auto 5.5vh;\n    border-radius: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n\n.formIntro_mssg {\n    width: 70vw;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.formIntro_img {\n    width: 67vw;\n    height: 37.5vh;\n    object-fit: contain;\n}\n\n.formIntro_next_img {\n    width: 7vw;\n    height: 4vh;\n    margin: 0 0 0 3.5vw;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
