import {
  BACK_QUES_FAIL,
  BACK_QUES_REQUEST,
  CREATE_CHAT_FAIL,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  FINISH_FORM_SUCCESS,
  GET_CUR_QUES_FAIL,
  GET_CUR_QUES_REQUEST,
  GET_CUR_QUES_SUCCESS,
  GET_SNOMED_FINDINGS_PASS,
  GET_SNOMED_FINDINGS_REQUEST,
  RESET_CHAT_FAIL,
  RESET_CHAT_REQUEST,
  SUBMIT_RESP_FAIL,
  SET_LANG_FAIL,
  SUBMIT_RESP_REQUEST,
} from "../types";

const initialState = {
  form_loading: false,
  ques_loading: false,
  search_loading: false,
  form_finished: false,
  isChatHistAvlbl: false,
  error: null,
};

const formStatus = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CHAT_REQUEST:
      return {
        ...state,
        form_loading: true,
      };
    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        isChatHistAvlbl: payload.isChatHistAvlbl,
        form_loading: false,
      };
    case CREATE_CHAT_FAIL:
    case BACK_QUES_FAIL:
    case SUBMIT_RESP_FAIL:
    case GET_CUR_QUES_FAIL:
    case RESET_CHAT_FAIL:
    case SET_LANG_FAIL:
      return {
        ...state,
        error: payload,
        form_loading: false,
        ques_loading: false,
        search_loading: false,
      };
    case BACK_QUES_REQUEST:
    case SUBMIT_RESP_REQUEST:
    case GET_CUR_QUES_REQUEST:
    case RESET_CHAT_REQUEST:
      return {
        ...state,
        ques_loading: true,
        form_finished: false,
      };
    case GET_CUR_QUES_SUCCESS:
      return {
        ...state,
        ques_loading: false,
      };
    case FINISH_FORM_SUCCESS:
      return {
        ...state,
        ques_loading: false,
        form_finished: true,
      };
    case GET_SNOMED_FINDINGS_REQUEST:
      return {
        ...state,
        search_loading: true,
      };
    case GET_SNOMED_FINDINGS_PASS:
      return {
        ...state,
        search_loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export { formStatus };
