import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, put, call } from "redux-saga/effects";
import { formatUserResp, getLang } from "../../util/helper";
import {
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  CREATE_CHAT_FAIL,
} from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const formatResp = (resData) => {
  const langOpt = getLang(resData.lang_id);
  const respArr = resData.history?.responses;
  const quesArr = resData.history?.questions;
  const chatArr = [];
  const formCache = {};
  if (respArr) {
    for (let index = 0; index < respArr.length; index++) {
      const respItm = respArr[index];
      const quesItm = quesArr[index];
      if (quesItm?.prompt?.text) {
        chatArr.push({
          qid: quesItm.qid,
          src: "bot",
          prompt: quesItm.prompt,
          type: quesItm.type,
        });
      }
      if (respItm.values.length !== 0) {
        chatArr.push({
          qid: respItm.qid,
          src: "user",
          prompt: formatUserResp(respItm.values, langOpt.value),
          type: quesItm.type,
        });
        formCache[quesItm.qid] = respItm.values;
      }
    }
  }
  return {
    Token: resData.Token,
    langOpt: langOpt,
    isChatHistAvlbl: chatArr.length === 0 ? false : true,
    chatQ: chatArr,
    formResp: formCache,
  };
};

const getToken = ({ payload }) => {
  return axios.request({
    method: "POST",
    data: {
      ref_id: payload.ref_id,
      token: payload.token,
      q_set_id: payload.q_set_id,
      srcTyp: payload.srcTyp,
      age: payload.pAge,
      gender: payload.pGender,
      lang_id: payload.lang_id,
    },
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/create`,
  });
};

function* initChat(payload) {
  try {
    const res = yield call(getToken, payload);
    if (res.data) {
      const initInfo = formatResp(res.data);
      yield put({ type: CREATE_CHAT_SUCCESS, payload: initInfo });
    } else {
      yield put({ type: CREATE_CHAT_FAIL, payload: res.error });
    }
  } catch (error) {
    yield put({ type: CREATE_CHAT_FAIL, payload: error });
  }
}

function* initForm() {
  yield takeEvery(CREATE_CHAT_REQUEST, initChat);
}

export { initForm };
