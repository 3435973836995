import esanjeevaniLoader from "../../../assets/images/esanjeevani_loader.png";
import "./styles.css";

const FormLoader = () => {
  return (
    <div className="formLoader">
      <div className="formLoaderBackground" />
      <div className="formLoaderDiv">
        <img
          className="formLoaderLogo"
          src={esanjeevaniLoader}
          alt="formLoaderLogo"
        />
        <span className="formLoaderTxt">{"Loading..."}</span>
      </div>
    </div>
  );
};

export { FormLoader };
