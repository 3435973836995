// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiSelectOptWrapper{
    max-height: 13.5rem;
    overflow: hidden;
    padding-bottom: 0.5rem;
}
.multiSelectOpt {
    width: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.multiSelectAddToListNAcontainer{
    display: flex;
    flex-wrap: wrap;
}

@media (orientation: landscape){
    .multiSelectOptWrapper{
        max-height: 6.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/fields/userInput/multiSelect/styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".multiSelectOptWrapper{\n    max-height: 13.5rem;\n    overflow: hidden;\n    padding-bottom: 0.5rem;\n}\n.multiSelectOpt {\n    width: inherit;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n.multiSelectAddToListNAcontainer{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n@media (orientation: landscape){\n    .multiSelectOptWrapper{\n        max-height: 6.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
