import axios from "axios";
import { takeEvery, put, call } from "redux-saga/effects";
import { hashCode } from "../../util/helper";
import {
  GET_SNOMED_FINDINGS_PASS,
  GET_SNOMED_FINDINGS_REQUEST,
} from "../types";

const fetchSnomedFindings = ({ payload }) => {
  return axios.get(
    `https://browser.ihtsdotools.org/snowstorm/snomed-ct/browser/MAIN/2022-06-30/descriptions?&limit=10&term=${payload}&active=true&conceptActive=true&lang=english&semanticTags=disorder&semanticTags=finding&groupByConcept=true`
  );
};

function* reqSnomedFindings(payload) {
  let snomedFindings = [];
  try {
    const res = yield call(fetchSnomedFindings, payload);
    if (res.data && res.data.items.length !== 0) {
      snomedFindings = res.data.items.map((itm) => ({
        value: hashCode(itm.term),
        prompt: { text: itm.term },
      }));
    }
  } catch {}
  yield put({ type: GET_SNOMED_FINDINGS_PASS, payload: snomedFindings });
}

function* getSnomedFindings() {
  yield takeEvery(GET_SNOMED_FINDINGS_REQUEST, reqSnomedFindings);
}

export { getSnomedFindings };
