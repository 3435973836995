import { useSelector } from "react-redux";
import clearResponseDialog_cancel from "../../../assets/images/closeFormDialog_cancel.png";
import clearResponseDialog_icn from "../../../assets/images/closeFormDialog_icn.png";
import {
  CANCEL_PROMPT,
  CLEAR_RESPONSE_BODY_DIALOG_MSSG,
  CLEAR_RESPONSE_TITLE_DIALOG_MSSG,
  REMOVE_CONFIRM_PROMPT,
} from "../../../util/const";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import "./styles.css";

const ClearResponseDialog = ({ handleChange, canProceed, setProceed }) => {
  const langOpt = useSelector(getLangOptFromState);
  const handleProceed = () => {
    handleChange(canProceed, false);
    setProceed(null);
  };

  const formatText = () => {
    const _txt = `${getTextFrmPrompt(canProceed?.prompt?.text, langOpt.value)}`;
    const _initTxt = `${getTextFrmPrompt(
      CLEAR_RESPONSE_TITLE_DIALOG_MSSG,
      langOpt.value
    )}`;
    const retString = _initTxt.replace("{}", _txt);
    return retString;
  };

  return (
    <div className="clearResponseDialog">
      <div className="clearResponseDialogBackground" />
      <div className="clearResponseDialogBox">
        <img
          className="clearResponseDialog_cancel"
          src={clearResponseDialog_cancel}
          alt={"clearResponseDialog_cancel"}
          onClick={() => setProceed(null)}
        />
        <img
          className="clearResponseDialog_icn"
          src={clearResponseDialog_icn}
          alt={"clearResponseDialog_icn"}
        />
        <p className="clearResponseDialog_mssg">{formatText()}</p>
        <p
          className="clearResponseDialog_mssg"
          style={{ marginTop: "0", fontWeight: "500" }}
        >
          {getTextFrmPrompt(CLEAR_RESPONSE_BODY_DIALOG_MSSG, langOpt.value)}
        </p>
        <div className="clearResponseDialog_action">
          <span
            className="clearResponseDialog_action_btn clearResponseDialog_btn_left"
            onClick={handleProceed}
          >
            {getTextFrmPrompt(REMOVE_CONFIRM_PROMPT, langOpt.value)}
          </span>
          <span
            className="clearResponseDialog_action_btn clearResponseDialog_btn_right"
            onClick={() => setProceed(null)}
          >
            {getTextFrmPrompt(CANCEL_PROMPT, langOpt.value)}
          </span>
        </div>
      </div>
    </div>
  );
};

export { ClearResponseDialog };
