import {
  CHAT_HEAD_STATUS_FONT_SIZE,
  CHAT_HEAD_STATUS_LINE_HEIGHT,
} from "../../../res/resource";
import clear_txt from "../../../assets/images/clear_txt.png";
import search_icn from "../../../assets/images/search_icn.png";
import { getLangOptFromState, getTextFrmPrompt } from "../../../util/helper";
import { INP_PLACEHOLDER } from "../../../util/const";
import { useSelector } from "react-redux";
import "./styles.css";

const SearchText = ({ visibleTxt, handleChange, handleEnter }) => {
  const langOpt = useSelector(getLangOptFromState);
  return (
    <div className="searchText">
      <img className="search_icon" src={search_icn} alt="search icon" />
      <input
        className="searchText_input"
        style={{
          fontSize: CHAT_HEAD_STATUS_FONT_SIZE,
          lineHeight: CHAT_HEAD_STATUS_LINE_HEIGHT,
        }}
        value={visibleTxt}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={handleEnter}
        placeholder={getTextFrmPrompt(INP_PLACEHOLDER, langOpt.value)}
      />
      {visibleTxt && (
        <img
          className="searchText_input_img"
          src={clear_txt}
          alt="Chat Char Icon"
          onClick={() => handleChange("")}
        />
      )}
    </div>
  );
};

export { SearchText };
