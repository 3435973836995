// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertDialogBackground {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}

.alertDialogBox {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 1.25rem;
    width: 25%;
    max-width: 300px;
    padding: 2%;
    text-align: center;
    @media screen and (orientation:portrait){
        width: 70%;
    }
}

.alert_itm{
    width: 20%;
    align-self: center;
    object-fit: contain;
    @media screen and (orientation:portrait){
        height: 20%;
    }
}

.alertFormDialog h3:last-child{
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/fields/alertFormDialog/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,UAAU;AACd;;AAEA;IACI,eAAe;IACf,UAAU;IACV,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB;QACI,UAAU;IACd;AACJ;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB;QACI,WAAW;IACf;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".alertDialogBackground {\n    position: fixed;\n    top: 0;\n    left: 0;\n    opacity: 0.7;\n    background-color: #000000;\n    width: 100vw;\n    height: 100vh;\n    z-index: 2;\n}\n\n.alertDialogBox {\n    position: fixed;\n    z-index: 2;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    display: flex;\n    flex-direction: column;\n    background: #FFFFFF;\n    border-radius: 1.25rem;\n    width: 25%;\n    max-width: 300px;\n    padding: 2%;\n    text-align: center;\n    @media screen and (orientation:portrait){\n        width: 70%;\n    }\n}\n\n.alert_itm{\n    width: 20%;\n    align-self: center;\n    object-fit: contain;\n    @media screen and (orientation:portrait){\n        height: 20%;\n    }\n}\n\n.alertFormDialog h3:last-child{\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
