// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nextDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.nextDiv_img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    padding-left: 0.66rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/actionButton/nextBtn/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".nextDiv {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n}\n\n.nextDiv_img {\n    width: 2.5rem;\n    height: 2.5rem;\n    object-fit: contain;\n    padding-left: 0.66rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
