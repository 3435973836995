import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, put, call, select, all } from "redux-saga/effects";
import { getSessionIDFromState } from "../../util/helper";
import {
  BACK_QUES_FAIL,
  BACK_QUES_REQUEST,
  BACK_QUES_SUCCESS,
  SKIP_FORM_FAIL_SAFE,
} from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const fetchPrevQues = (session_id) => {
  return axios.request({
    method: "PUT",
    headers: { "x-access-token": session_id },
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/back`,
  });
};

function* getPrevQues() {
  try {
    const session_id = yield select(getSessionIDFromState);
    const res = yield call(fetchPrevQues, session_id);
    if (res.data) {
      yield put({ type: BACK_QUES_SUCCESS });
    } else {
      yield all([
        put({ type: BACK_QUES_FAIL, payload: res.error }),
        put({
          type: SKIP_FORM_FAIL_SAFE,
          payload: { err: res.error, errInfo: "getPrevQuesFailed" },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: BACK_QUES_FAIL, payload: error }),
      put({
        type: SKIP_FORM_FAIL_SAFE,
        payload: { err: error, errInfo: "getPrevQuesFailed" },
      }),
    ]);
  }
}

function* reqPrevQues() {
  yield takeEvery(BACK_QUES_REQUEST, getPrevQues);
}

export { reqPrevQues };
