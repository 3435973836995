import axios from "axios";
import axiosRetry from "axios-retry";
import { takeEvery, call, select } from "redux-saga/effects";
import { getSessionIDFromState } from "../../util/helper";
import { SKIP_FORM_FAIL_SAFE } from "../types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

const postFormFailSafe = (payload, session_id) => {
  return axios.request({
    method: "POST",
    headers: { "x-access-token": session_id },
    data: payload,
    url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENV}/api/formSkip`,
  });
};

function* handleFormFailSafe({ payload }) {
  try {
    const session_id = yield select(getSessionIDFromState);
    yield call(postFormFailSafe, payload, session_id);
  } catch (error) {}
}

function* skipFormFailSafe() {
  yield takeEvery(SKIP_FORM_FAIL_SAFE, handleFormFailSafe);
}

export { skipFormFailSafe };
