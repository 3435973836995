export const GET_CUR_QUES_REQUEST = "GET_CUR_QUES_REQUEST";
export const GET_CUR_QUES_SUCCESS = "GET_CUR_QUES_SUCCESS";
export const GET_CUR_QUES_FAIL = "GET_CUR_QUES_FAIL";

export const SUBMIT_RESP_REQUEST = "SUBMIT_RESP_REQUEST";
export const SUBMIT_RESP_SUCCESS = "SUBMIT_RESP_SUCCESS";
export const SUBMIT_RESP_FAIL = "SUBMIT_RESP_FAIL";

export const BACK_QUES_REQUEST = "BACK_QUES_REQUEST";
export const BACK_QUES_SUCCESS = "BACK_QUES_SUCCESS";
export const BACK_QUES_FAIL = "BACK_QUES_FAIL";

export const RESET_CHAT_REQUEST = "RESET_CHAT_REQUEST";
export const RESET_CHAT_SUCCESS = "RESET_CHAT_SUCCESS";
export const RESET_CHAT_FAIL = "RESET_CHAT_FAIL";

export const CREATE_CHAT_REQUEST = "CREATE_CHAT_REQUEST";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const CREATE_CHAT_FAIL = "CREATE_CHAT_FAIL";

export const GET_SNOMED_FINDINGS_REQUEST = "GET_SNOMED_FINDINGS_REQUEST";
export const GET_SNOMED_FINDINGS_PASS = "GET_SNOMED_FINDINGS_PASS";

export const UPDT_CUR_RESP = "UPDT_CUR_RESP";

export const SET_LANG_REQUEST = "SET_LANG_REQUEST";
export const SET_LANG_SUCCESS = "SET_LANG_SUCCESS";
export const SET_LANG_FAIL = "SET_LANG_FAIL";

export const SET_INITIALS = "SET_INITIALS";
export const SET_OPT_SCROLLABLE = "SET_OPT_SCROLLABLE";

export const FINISH_FORM_SUCCESS = "FINISH_FORM_SUCCESS";
export const SKIP_FORM_FAIL_SAFE = "SKIP_FORM_FAIL_SAFE";
