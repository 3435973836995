import { useSelector } from "react-redux";
import { getLangOptFromState, getTextFrmPrompt } from "../../../../util/helper";
import "./styles.css";

const ActionBtn = ({
  isCurQuesUpdt,
  onClick,
  respArr,
  btnPrompt,
  actionBtn_icn,
}) => {
  const handleActionBtnClick = () => {
    if (isCurQuesUpdt) {
      onClick(respArr);
    }
  };
  const langOpt = useSelector(getLangOptFromState);
  return (
    <div
      className={`actionBtn ${
        isCurQuesUpdt ? "actionBtn_enable" : "actionBtn_disable"
      }`}
      onClick={handleActionBtnClick}
    >
      <span className="actionBtn_txt">
        {getTextFrmPrompt(btnPrompt, langOpt.value)}
      </span>
      <img className="actionBtn_img" src={actionBtn_icn} alt="actionBtn_icn" />
    </div>
  );
};
export { ActionBtn };
